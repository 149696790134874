// Hero.jsx (Fullständig fil)
import React from 'react';
import { Button } from '@/components/ui/button';
import { ArrowRight, Shield, Clock, Truck, MapPin } from 'lucide-react';
import { scrollToSection } from '@/utils/scroll';

const Hero = () => {
  const scrollToPrice = () => {
    const priceSection = document.getElementById('priser');
    if (priceSection) {
      const navHeight = 64;
      const sectionTop = priceSection.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: sectionTop - navHeight,
        behavior: 'smooth'
      });
    }
  };

  return (
    <section
      id="hem"
      className="relative bg-gradient-to-b from-primary-50 to-white overflow-hidden"
      aria-labelledby="hero-heading"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center">
          <div className="inline-flex items-center px-4 py-2 bg-primary-100 text-primary-800 rounded-full text-sm font-medium mb-6">
            <MapPin className="w-4 h-4 mr-2" />
            Expressleverans i hela Stockholms län
          </div>

          <h1
            id="hero-heading"
            className="text-4xl md:text-5xl lg:text-6xl font-bold text-secondary-900 mb-6 animate-fade-in"
          >
            Kvalitativa Byggstängsel för alla Projekt
          </h1>

          <p className="text-xl text-secondary-600 mb-8 max-w-3xl mx-auto animate-slide-up delay-100">
            Sveriges mest kompletta lösning för byggstängsel.
            Snabb leverans inom 48h och flexibla hyresperioder till konkurrenskraftiga priser.
          </p>

          <div className="flex flex-col sm:flex-row gap-4 justify-center mb-12">
            <Button
              size="lg"
              className="group"
              onClick={scrollToPrice}
            >
              Se priser direkt
              <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" />
            </Button>
            <Button
              variant="outline"
              size="lg"
              onClick={() => window.location.href = 'tel:+46762520046'}
            >
              Ring direkt: 076-252 00 46
            </Button>
          </div>

          <div className="grid md:grid-cols-3 gap-8 max-w-4xl mx-auto">
            <div className="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow">
              <Shield className="w-12 h-12 text-primary-600 mb-4 mx-auto" />
              <h2 className="text-xl font-semibold mb-2">ISO-certifierad</h2>
              <p className="text-secondary-600">
                Alla våra stängsel uppfyller SS-EN ISO 9001:2015
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow">
              <Clock className="w-12 h-12 text-primary-600 mb-4 mx-auto" />
              <h2 className="text-xl font-semibold mb-2">Flexibel Uthyrning</h2>
              <p className="text-secondary-600">
                Från 1 månad upp till flera år
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow">
              <Truck className="w-12 h-12 text-primary-600 mb-4 mx-auto" />
              <h2 className="text-xl font-semibold mb-2">Snabb Leverans</h2>
              <p className="text-secondary-600">
                Leverans inom 48h i hela Stockholm
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;