class ApiService {
  constructor() {
    this.baseURL = import.meta.env.VITE_API_URL || '/api';
    this.defaultHeaders = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
  }

  // Generisk fetch-metod med felhantering och retries
  async fetchWithRetry(endpoint, options = {}, retries = 3) {
    const url = `${this.baseURL}${endpoint}`;
    const headers = { ...this.defaultHeaders, ...options.headers };

    for (let i = 0; i < retries; i++) {
      try {
        const response = await fetch(url, { ...options, headers });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Ett nätverksfel uppstod');
        }

        return await response.json();
      } catch (error) {
        if (i === retries - 1) throw error;
        await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, i)));
      }
    }
  }

  // Skicka kontaktformulär
  async submitContactForm(formData) {
    return this.fetchWithRetry('/contact', {
      method: 'POST',
      body: JSON.stringify(formData)
    });
  }

  // Begär prisuppskattning
  async getPriceEstimate(params) {
    return this.fetchWithRetry('/price-estimate', {
      method: 'POST',
      body: JSON.stringify(params)
    });
  }

  // Skapa offert
  async createQuote(quoteData) {
    return this.fetchWithRetry('/quotes', {
      method: 'POST',
      body: JSON.stringify(quoteData)
    });
  }

  // Hämta offert
  async getQuote(quoteId) {
    return this.fetchWithRetry(`/quotes/${quoteId}`);
  }

  // Kontrollera postnummer för leverans
  async checkDeliveryAvailability(postalCode) {
    return this.fetchWithRetry(`/delivery/check/${postalCode}`);
  }

  // Hämta tillgängliga leveranstider
  async getDeliverySlots(postalCode, date) {
    return this.fetchWithRetry('/delivery/slots', {
      method: 'POST',
      body: JSON.stringify({ postalCode, date })
    });
  }

  // Boka leveranstid
  async scheduleDelivery(deliveryData) {
    return this.fetchWithRetry('/delivery/schedule', {
      method: 'POST',
      body: JSON.stringify(deliveryData)
    });
  }

  // Hämta orderhistorik
  async getOrderHistory(customerId) {
    return this.fetchWithRetry(`/orders/${customerId}`);
  }

  // Uppdatera orderstatus
  async updateOrderStatus(orderId, status) {
    return this.fetchWithRetry(`/orders/${orderId}/status`, {
      method: 'PATCH',
      body: JSON.stringify({ status })
    });
  }

  // Ladda upp filer
  async uploadFiles(files, orderId) {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));
    formData.append('orderId', orderId);

    return this.fetchWithRetry('/uploads', {
      method: 'POST',
      headers: {}, // Låt browsern sätta rätt Content-Type för FormData
      body: formData
    });
  }

  // Hämta företagsinformation från organisationsnummer
  async getCompanyInfo(orgNumber) {
    return this.fetchWithRetry(`/company-info/${orgNumber}`);
  }

  // Validera adress
  async validateAddress(address) {
    return this.fetchWithRetry('/validate-address', {
      method: 'POST',
      body: JSON.stringify(address)
    });
  }

  // Spara utkast
  async saveDraft(draftData) {
    return this.fetchWithRetry('/drafts', {
      method: 'POST',
      body: JSON.stringify(draftData)
    });
  }

  // Hämta utkast
  async getDraft(draftId) {
    return this.fetchWithRetry(`/drafts/${draftId}`);
  }

  // Skicka bekräftelsemail
  async sendConfirmationEmail(emailData) {
    return this.fetchWithRetry('/send-confirmation', {
      method: 'POST',
      body: JSON.stringify(emailData)
    });
  }

  // Hantera webhooks
  async handleWebhook(webhookData) {
    return this.fetchWithRetry('/webhooks', {
      method: 'POST',
      body: JSON.stringify(webhookData)
    });
  }
}

export default new ApiService();