// Policies.jsx
import React from 'react';
import { Card } from '@/components/ui/card';
import { FileText, Shield, Scale, FileCheck } from 'lucide-react';
import { SEOProvider, generatePageSEO } from '@/components/seo/SEOProvider';

const Policies = () => {
  const pageSEO = generatePageSEO('/policies');

  const documents = [
    {
      title: 'Hyresavtal',
      description: 'Fullständigt hyresavtal med alla villkor och bestämmelser.',
      icon: FileCheck,
      link: '/documents/hyresavtal.pdf'
    },
    {
      title: 'Hyresvillkor',
      description: 'Generella villkor för uthyrning av byggstängsel.',
      icon: Scale,
      link: '/documents/hyresvillkor.pdf'
    },
    {
      title: 'Integritetspolicy',
      description: 'Information om hur vi hanterar personuppgifter.',
      icon: Shield,
      link: '/documents/integritetspolicy.pdf'
    },
    {
      title: 'Försäkringsvillkor',
      description: 'Villkor för vår tilläggsförsäkring.',
      icon: FileText,
      link: '/documents/forsakringsvillkor.pdf'
    }
  ];

  return (
    <SEOProvider pageSEO={pageSEO}>
      <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Header */}
          <div className="text-center mb-12">
            <h1 className="text-3xl font-bold text-gray-900">Villkor & Policies</h1>
            <p className="mt-4 text-lg text-gray-600">
              Här hittar du alla viktiga dokument relaterade till våra tjänster
            </p>
          </div>

          {/* Sammanfattning */}
          <Card className="mb-12 p-6 bg-white">
            <h2 className="text-xl font-semibold mb-4">Viktigt att veta</h2>
            <div className="space-y-3 text-gray-600">
              <p>• Minsta hyresperiod är 1 månad</p>
              <p>• Vid löpande hyra gäller 1 månads uppsägningstid</p>
              <p>• Hyrestagaren ansvarar för stängslet under hela hyresperioden</p>
              <p>• Fakturering sker månadsvis i förskott</p>
              <p>• Försäkring kan tecknas som tillägg</p>
            </div>
          </Card>

          {/* Dokumentgrid */}
          <div className="grid md:grid-cols-2 gap-6">
            {documents.map((doc, index) => (
              <Card
                key={index}
                className="p-6 hover:shadow-lg transition-shadow"
              >
                <div className="flex items-start gap-4">
                  <div className="p-3 bg-blue-50 rounded-lg">
                    <doc.icon className="w-6 h-6 text-blue-600" />
                  </div>
                  <div className="flex-1">
                    <h3 className="text-lg font-semibold text-gray-900">{doc.title}</h3>
                    <p className="text-gray-600 mb-4">{doc.description}</p>
                    <a
                      href={doc.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center text-blue-600 hover:text-blue-800"
                    >
                      Ladda ner PDF
                      <FileText className="w-4 h-4 ml-2" />
                    </a>
                  </div>
                </div>
              </Card>
            ))}
          </div>

          {/* Ytterligare information */}
          <div className="mt-12 text-center">
            <p className="text-gray-600">
              Har du frågor om våra villkor eller policies?{' '}
              <a href="/kontakt" className="text-blue-600 hover:text-blue-800">
                Kontakta oss
              </a>
            </p>
          </div>
        </div>
      </div>
    </SEOProvider>
  );
};

export default Policies;