import React, { useState, useEffect } from 'react';
import { Menu, X, Phone } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Link, useLocation } from 'react-router-dom';
import { scrollToSection } from '@/utils/scroll';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = [
    { name: 'Hem', href: '/', ariaLabel: 'Gå till startsidan' },
    { name: 'Fördelar', href: '/#fordelar', ariaLabel: 'Se våra fördelar' },
    { name: 'Priser', href: '/#priser', ariaLabel: 'Se våra priser' },
    { name: 'Blogg', href: '/blogg', ariaLabel: 'Besök vår kunskapsbank' },
    { name: 'Kontakt', href: '/#kontakt', ariaLabel: 'Kontakta oss' },
    { name: 'Villkor', href: '/policies', ariaLabel: 'Se våra villkor och policies' },
  ];

  const handleNavigation = (href) => {
    setIsOpen(false);

    // Om det är en fullständig sida (som /policies), använd vanlig navigering
    if (!href.includes('#')) {
      window.location.href = href;
      return;
    }

    // Om vi inte är på hemsidan och försöker nå en ankartagg, navigera först till hemsidan
    if (location.pathname !== '/' && href.startsWith('/#')) {
      window.location.href = href;
      return;
    }

    // För ankarlänkar på hemsidan, använd smooth scroll
    if (href.startsWith('/#')) {
      const sectionId = href.replace('/#', '');
      scrollToSection(sectionId);
    } else if (href === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? 'bg-white shadow-md' : 'bg-white/95'
      }`}
    >
      <nav
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
        role="navigation"
        aria-label="Huvudmeny"
      >
        <div className="flex justify-between h-16">
          {/* Logo */}
          <div className="flex items-center">
            <Link
              to="/"
              className="text-2xl font-bold hover:opacity-90 transition-opacity"
              aria-label="Gå till startsidan"
              onClick={() => {
                setIsOpen(false);
                handleNavigation('/');
              }}
            >
              <span className="font-extrabold tracking-tight">
                <span className="text-primary-600">Bygg</span>
                <span className="text-secondary-600">Stängsel</span>
                <span className="text-primary-600">PRO</span>
              </span>
            </Link>
          </div>

          {/* Desktop navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <div className="flex space-x-8">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-secondary-600 hover:text-primary-600 px-3 py-2 text-sm font-medium transition-colors"
                  aria-label={item.ariaLabel}
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigation(item.href);
                  }}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <Button
              variant="primary"
              size="sm"
              className="flex items-center gap-2"
              onClick={() => window.location.href = 'tel:+46762520046'}
              aria-label="Ring oss nu"
            >
              <Phone className="w-4 h-4" />
              <span>076-252 00 46</span>
            </Button>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="p-2 rounded-md text-secondary-400 hover:text-secondary-500 hover:bg-secondary-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
              aria-expanded={isOpen}
              aria-controls="mobile-menu"
              aria-label={isOpen ? 'Stäng meny' : 'Öppna meny'}
            >
              {isOpen ? (
                <X className="h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        <div
          className={`md:hidden absolute left-0 right-0 bg-white px-4 pt-2 pb-3 border-b shadow-lg transition-transform duration-200 ease-in-out ${
            isOpen ? 'translate-y-0' : '-translate-y-full'
          }`}
          id="mobile-menu"
          role="menu"
          aria-labelledby="mobile-menu-button"
        >
          <div className="space-y-1">
            {navItems.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className="block px-3 py-2 text-base font-medium text-secondary-600 hover:text-primary-600 hover:bg-secondary-50 rounded-md"
                role="menuitem"
                aria-label={item.ariaLabel}
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigation(item.href);
                }}
              >
                {item.name}
              </Link>
            ))}
            <Button
              variant="primary"
              size="sm"
              className="flex items-center gap-2 mt-4 w-full justify-center"
              onClick={() => window.location.href = 'tel:+46762520046'}
              aria-label="Ring oss nu"
            >
              <Phone className="w-4 h-4" />
              <span>076-252 00 46</span>
            </Button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navigation;