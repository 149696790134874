import React, { useState, useEffect } from 'react';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Phone, Shield, AlertTriangle } from 'lucide-react';
import { scrollToSection } from '@/utils/scroll';

const SECTION_LENGTH = 3.5;
const PRICE_PER_SECTION = 8;
const MIN_LENGTH = 10;
const MAX_LENGTH = 1000;

const PriceDisplay = ({ label, value, isHighlighted = false }) => (
  <div className="flex justify-between items-center">
    <span className={isHighlighted ? "font-medium" : ""}>{label}</span>
    <span className={isHighlighted ? "font-medium" : ""}>{value}</span>
  </div>
);

const Pricing = () => {
  const [length, setLength] = useState(105);
  const [duration, setDuration] = useState(30);
  const [isLongTerm, setIsLongTerm] = useState(false);
  const [sections, setSections] = useState(0);
  const [extras, setExtras] = useState({
    insurance: false
  });

  useEffect(() => {
    const calculatedSections = Math.ceil(length / SECTION_LENGTH);
    setSections(calculatedSections);
  }, [length]);

  const getDiscountRate = () => {
    if (duration >= 180) return 0.08;
    if (duration >= 90) return 0.05;
    return 0;
  };

  const calculatePricing = () => {
    const basePrice = sections * PRICE_PER_SECTION * duration;
    const discount = basePrice * getDiscountRate();
    const insuranceCost = extras.insurance ? basePrice * 0.05 : 0;

    return {
      basePrice,
      discount,
      extraCosts: insuranceCost,
      total: basePrice - discount + insuranceCost,
      pricePerDay: sections * PRICE_PER_SECTION,
      pricePerMonth: (basePrice - discount) / (duration / 30)
    };
  };

  const formatPrice = (amount) => {
    return new Intl.NumberFormat('sv-SE', {
      style: 'currency',
      currency: 'SEK',
      maximumFractionDigits: 0
    }).format(amount);
  };

  const pricing = calculatePricing();

  return (
    <section id="priser" className="py-12 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Beräkna ditt pris</h2>
          <p className="text-xl text-gray-600">
            Transparent prissättning utan dolda avgifter. Frakt tillkommer.
          </p>
        </div>

        <div className="grid lg:grid-cols-2 gap-8">
          {/* Vänster kolumn - Input */}
          <div className="space-y-8">
            <Card className="p-6">
              <h3 className="text-lg font-medium mb-4">Längd på stängsel</h3>
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <input
                    type="range"
                    min={MIN_LENGTH}
                    max={MAX_LENGTH}
                    step={SECTION_LENGTH}
                    value={length}
                    onChange={(e) => setLength(Number(e.target.value))}
                    className="w-full accent-blue-600"
                    aria-label="Välj längd på stängsel"
                  />
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-medium">{length} meter</span>
                  <div className="text-sm text-gray-600">
                    {sections} sektioner ({SECTION_LENGTH}m/sektion)
                  </div>
                </div>
              </div>
            </Card>

            <Card className="p-6">
              <h3 className="text-lg font-medium mb-4">Hyresperiod</h3>
              <div className="space-y-4">
                <label className="flex items-center mb-4 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={isLongTerm}
                    onChange={(e) => {
                      setIsLongTerm(e.target.checked);
                      if (e.target.checked && duration < 90) {
                        setDuration(90);
                      }
                    }}
                    className="mr-2 w-4 h-4 accent-blue-600"
                  />
                  <span>Löpande hyra (1 månads uppsägningstid)</span>
                </label>
                <select
                  value={duration}
                  onChange={(e) => setDuration(Number(e.target.value))}
                  className="w-full p-2 border rounded focus:border-blue-600 focus:ring-1 focus:ring-blue-600"
                  aria-label="Välj hyresperiod"
                >
                  {!isLongTerm && <option value="30">1 månad</option>}
                  <option value="90">3 månader (5% rabatt)</option>
                  <option value="180">6 månader (8% rabatt)</option>
                </select>
              </div>
            </Card>

            <Card className="p-6">
              <h3 className="text-lg font-medium mb-4">Tillval</h3>
              <div className="space-y-3">
                <label className="flex items-center justify-between p-3 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer">
                  <div className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      checked={extras.insurance}
                      onChange={(e) => setExtras({ ...extras, insurance: e.target.checked })}
                      className="w-4 h-4 accent-blue-600"
                    />
                    <div>
                      <div className="font-medium flex items-center gap-2">
                        <Shield className="w-4 h-4" />
                        Försäkring
                      </div>
                      <span className="text-sm text-gray-600">
                        Omfattande försäkring under hela hyresperioden
                      </span>
                    </div>
                  </div>
                  <span>5% av hyran</span>
                </label>
              </div>
            </Card>
          </div>

          {/* Höger kolumn - Sammanfattning */}
          <div className="space-y-6">
            <Card className="p-6 bg-blue-50">
              <h3 className="text-lg font-medium mb-4">Specifikation</h3>
              <div className="space-y-2">
                <PriceDisplay label="Total längd" value={`${length} meter`} />
                <PriceDisplay label="Antal sektioner" value={`${sections} st`} />
                <PriceDisplay
                  label="Kostnad per sektion"
                  value={`${formatPrice(PRICE_PER_SECTION)}/dag`}
                />
                <div className="pt-2 border-t mt-2">
                  <PriceDisplay
                    label="Total dagskostnad"
                    value={`${formatPrice(pricing.pricePerDay)}/dag`}
                    isHighlighted={true}
                  />
                </div>
              </div>
            </Card>

            {pricing.discount > 0 && (
              <Card className="p-6 bg-green-50">
                <h3 className="text-lg font-medium mb-4">Din rabatt</h3>
                <div className="flex justify-between text-green-700 font-medium">
                  <span>Långtidsrabatt ({(getDiscountRate() * 100)}%)</span>
                  <span>-{formatPrice(pricing.discount)}</span>
                </div>
              </Card>
            )}

            {pricing.extraCosts > 0 && (
              <Card className="p-6">
                <h3 className="text-lg font-medium mb-4">Tillvalskostnader</h3>
                <div className="space-y-2">
                  {extras.insurance && (
                    <PriceDisplay
                      label="Försäkring (5%)"
                      value={formatPrice(pricing.extraCosts)}
                    />
                  )}
                </div>
              </Card>
            )}

            <Card className="p-6">
              <div className="flex justify-between items-center text-gray-900">
                <span>Månadskostnad</span>
                <span className="font-medium text-xl">
                  {formatPrice(pricing.pricePerMonth)}/mån
                </span>
              </div>
            </Card>

            <div className="bg-white rounded-xl shadow-lg overflow-hidden">
              <div className="p-6 bg-blue-600">
                <h3 className="text-lg font-semibold mb-4 text-white">Totalt att betala</h3>
                <div className="bg-white rounded-lg p-4">
                  <div className="space-y-3">
                    <PriceDisplay
                      label="Totalt ex. moms"
                      value={formatPrice(pricing.total)}
                      isHighlighted={true}
                    />
                    <div className="flex justify-between items-center text-gray-600 text-sm">
                      <span>Moms (25%)</span>
                      <span>{formatPrice(pricing.total * 0.25)}</span>
                    </div>
                    <div className="pt-3 border-t border-gray-200">
                      <div className="flex justify-between items-center">
                        <span className="font-semibold text-lg">Totalt inkl. moms</span>
                        <span className="font-bold text-xl">
                          {formatPrice(pricing.total * 1.25)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <Button
                className="w-full"
                size="lg"
                onClick={() => scrollToSection('kontakt')}
              >
                Begär offert
              </Button>
              <Button
                variant="outline"
                className="w-full"
                size="lg"
                onClick={() => window.location.href = 'tel:+46762520046'}
              >
                <Phone className="w-4 h-4 mr-2" />
                Ring oss: 076-252 00 46
              </Button>
            </div>

            <div className="text-sm text-gray-600 space-y-1">
              <p>* Alla priser är exklusive moms</p>
              <p>* Minsta hyresperiod är 1 månad</p>
              <p>* Frakt tillkommer</p>
              {isLongTerm && <p>* 1 månads uppsägningstid gäller för löpande hyra</p>}
            </div>
          </div>
        </div>

        {/* Schema.org markup för priser */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "PriceSpecification",
            "price": PRICE_PER_SECTION,
            "priceCurrency": "SEK",
            "unitText": "per sektion/dag",
            "validFrom": "2024-01-01",
            "validThrough": "2024-12-31"
          })}
        </script>
      </div>
    </section>
  );
};

export default Pricing;