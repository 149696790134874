// HomePage.jsx
import React from 'react';
import Hero from '@/components/sections/Hero';
import Benefits from '@/components/sections/Benefits';
import Pricing from '@/components/sections/Pricing';
import ContactForm from '@/components/sections/ContactForm';
import FAQSection from '@/components/sections/FAQ';
import { SEOProvider, generatePageSEO } from '@/components/seo/SEOProvider';

const HomePage = () => {
  const pageSEO = generatePageSEO('/');

  return (
    <SEOProvider pageSEO={pageSEO}>
      <Hero />
      <Benefits />
      <Pricing />
      <ContactForm />
      <FAQSection />
    </SEOProvider>
  );
};

export default HomePage;