import React from 'react';
import PropTypes from 'prop-types';

export const Card = ({
  children,
  className = '',
  onClick,
  hover = false,
  ...props
}) => {
  const baseClasses = 'bg-white rounded-xl shadow-md overflow-hidden';
  const hoverClasses = hover ? 'transform transition-transform duration-200 hover:scale-105' : '';

  return (
    <div
      className={`${baseClasses} ${hoverClasses} ${className}`}
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  hover: PropTypes.bool
};