import React from 'react';
import { Phone, Mail, MapPin, Facebook, Instagram, LinkedinIcon } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();

  const handleNavigation = (href) => {
    if (href.startsWith('/#') && location.pathname === '/') {
      const element = document.querySelector(href.replace('/', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const companyInfo = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "StängselPro AB",
    "image": "https://example.com/photos/1x1/photo.jpg",
    "telephone": "+46701234567",
    "email": "info@byggstängselpro.se",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Stängselvägen 1",
      "addressLocality": "Stockholm",
      "postalCode": "111 22",
      "addressCountry": "SE"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "59.3293",
      "longitude": "18.0686"
    },
    "url": "https://www.stangsel-pro.se",
    "sameAs": [
      "https://www.facebook.com/StangselPro",
      "https://www.instagram.com/StangselPro",
      "https://www.linkedin.com/company/StangselPro"
    ]
  };

  return (
    <footer
      className="bg-secondary-900 text-white"
      itemScope
      itemType="https://schema.org/WPFooter"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Företagsinfo */}
          <div>
            <h2 className="text-xl font-bold mb-4">StängselPro</h2>
            <p className="text-secondary-300 mb-4">
              Sveriges ledande leverantör av byggstängsel med över 20 års erfarenhet
              i branschen.
            </p>
            <div
                className="space-y-2"
                itemScope
                itemType="https://schema.org/LocalBusiness"
            >
              <a
                  href="tel:+46701234567"
                  className="flex items-center gap-2 text-secondary-300 hover:text-white transition-colors"
                  itemProp="telephone"
              >
                <Phone className="w-4 h-4"/>
                076-252 00 46
              </a>
              <a
                  href="mailto:info@byggstängselpro.se"
                  className="flex items-center gap-2 text-secondary-300 hover:text-white transition-colors"
                  itemProp="email"
              >
                <Mail className="w-4 h-4"/>
                info@byggstängselpro.se
              </a>
              <div
                  className="flex items-center gap-2 text-secondary-300"
                  itemProp="address"
                  itemScope
                  itemType="https://schema.org/PostalAddress"
              >
                <MapPin className="w-4 h-4"/>
                <span>
                  <span itemProp="addressLocality">Stockholm</span>
                </span>
              </div>
            </div>
          </div>

          {/* Snabblänkar */}
          <div>
            <h2 className="text-xl font-bold mb-4">Snabblänkar</h2>
            <nav className="space-y-2" aria-label="Footer navigation">
              <Link
                  to="/#hem"
                  onClick={() => handleNavigation('/#hem')}
                  className="block text-secondary-300 hover:text-white transition-colors"
              >
                Hem
              </Link>
              <Link
                  to="/#fordelar"
                  onClick={() => handleNavigation('/#fordelar')}
                  className="block text-secondary-300 hover:text-white transition-colors"
              >
                Fördelar
              </Link>
              <Link
                  to="/#priser"
                  onClick={() => handleNavigation('/#priser')}
                  className="block text-secondary-300 hover:text-white transition-colors"
              >
                Priser
              </Link>
              <Link
                  to="/blogg"
                  className="block text-secondary-300 hover:text-white transition-colors"
              >
                Kunskapsbank
              </Link>
              <Link
                  to="/#kontakt"
                  onClick={() => handleNavigation('/#kontakt')}
                  className="block text-secondary-300 hover:text-white transition-colors"
              >
                Kontakt
              </Link>
              <Link
                  to="/policies"
                  className="block text-secondary-300 hover:text-white transition-colors"
              >
                Villkor & Policies
              </Link>
            </nav>
          </div>

          {/* Tjänster */}
          <div>
            <h2 className="text-xl font-bold mb-4">Våra Tjänster</h2>
            <ul className="space-y-2">
              <li className="text-secondary-300">Byggstängsel uthyrning</li>
              <li className="text-secondary-300">Installation & montering</li>
              <li className="text-secondary-300">Transport & leverans</li>
              <li className="text-secondary-300">Teknisk rådgivning</li>
              <li className="text-secondary-300">Projektplanering</li>
            </ul>
          </div>

          {/* Sociala medier */}
          <div>
            <h2 className="text-xl font-bold mb-4">Följ Oss</h2>
            <div className="flex space-x-4">
              <a
                  href="https://facebook.com/StangselPro"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-secondary-300 hover:text-white transition-colors"
                  aria-label="Följ oss på Facebook"
              >
                <Facebook className="w-6 h-6"/>
              </a>
              <a
                  href="https://instagram.com/StangselPro"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-secondary-300 hover:text-white transition-colors"
                  aria-label="Följ oss på Instagram"
              >
                <Instagram className="w-6 h-6"/>
              </a>
              <a
                  href="https://linkedin.com/company/StangselPro"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-secondary-300 hover:text-white transition-colors"
                  aria-label="Följ oss på LinkedIn"
              >
                <LinkedinIcon className="w-6 h-6"/>
              </a>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-12 pt-8 border-t border-secondary-800">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-secondary-400">
              © {new Date().getFullYear()} StängselPro AB. Alla rättigheter förbehållna.
            </p>
            <div className="flex space-x-6 mt-4 md:mt-0">
              <Link to="/policies" className="text-secondary-400 hover:text-white transition-colors">
                Villkor & Policies
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(companyInfo)}
      </script>
    </footer>
  );
};

export default Footer;