// src/App.jsx
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FormProvider } from '@/context/FormContext';

// Layout
import MainLayout from '@/layouts/main/MainLayout';

// Pages
import HomePage from '@/pages/HomePage';
import Policies from '@/pages/Policies';
import AreaPage from '@/pages/locations/AreaPage';

// Blog Components
import BlogSection from '@/components/blog/BlogSection';
import BlogCategoryPage from '@/pages/blog/BlogCategoryPage';
import BlogPost from '@/components/blog/BlogPost';

console.log('App.jsx initializing...');

// Configure React Query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // Data is fresh for 5 minutes
      cacheTime: 1000 * 60 * 30, // Cache persists for 30 minutes
      retry: 1,
      refetchOnWindowFocus: false,
      onError: (error) => {
        console.error('Query error:', error);
      }
    },
  },
});

const App = () => {
  console.log('App component rendering');

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <FormProvider>
          <Router>
            <Routes>
              <Route path="/" element={<MainLayout />}>
                {/* Main routes */}
                <Route index element={<HomePage />} />
                <Route path="policies" element={<Policies />} />
                <Route path="stockholm/:area" element={<AreaPage />} />

                {/* Blog routes */}
                <Route path="blogg">
                  <Route index element={<BlogSection />} />
                  <Route path="kategori/:category" element={<BlogCategoryPage />} />
                  <Route path=":category/:slug" element={<BlogPost />} />
                </Route>

                {/* 404 Route */}
                <Route
                  path="*"
                  element={
                    <div className="min-h-screen flex items-center justify-center">
                      <div className="text-center">
                        <h1 className="text-4xl font-bold mb-4">
                          Sidan kunde inte hittas
                        </h1>
                        <p className="text-gray-600 mb-8">
                          Sidan du söker finns inte eller har flyttats.
                        </p>
                        <a
                          href="/"
                          className="text-blue-600 hover:text-blue-800 font-medium"
                        >
                          ← Tillbaka till startsidan
                        </a>
                      </div>
                    </div>
                  }
                />
              </Route>
            </Routes>
          </Router>
        </FormProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
};

export default App;