import React from 'react';
import { useParams } from 'react-router-dom';
import BlogCategory from '@/components/blog/BlogCategory';
import { SEOProvider } from '@/components/seo/SEOProvider';

const BlogCategoryPage = () => {
  const { category } = useParams();

  // Detta skulle normalt hämtas från en CMS eller API
  const getCategoryData = (categorySlug) => {
    const categories = {
      guider: {
        title: 'Guide till byggstängsel',
        description: 'Omfattande guider om olika typer av byggstängsel och användningsområden',
        seoDescription: 'Expertguider om byggstängsel - Allt du behöver veta om olika typer, användningsområden och bästa praxis för byggstängsel.',
        keywords: 'byggstängsel guide, stängseltyper, byggstängsel användning, stängsel installation'
      },
      sakerhet: {
        title: 'Säkerhet på byggarbetsplatsen',
        description: 'Expertråd och bästa praxis för en säker byggarbetsplats',
        seoDescription: 'Säkerhetsguider för byggarbetsplatser - Expertråd om säkerhetsåtgärder, regelverk och bästa praxis för byggstängsel.',
        keywords: 'byggarbetsplats säkerhet, säkerhetsåtgärder, arbetsmiljö byggplats'
      },
      regelverk: {
        title: 'Regelverk & Standarder',
        description: 'Aktuell information om regler och standarder inom byggsektorn',
        seoDescription: 'Uppdaterad information om regelverk och standarder för byggstängsel och arbetsplatssäkerhet.',
        keywords: 'byggstängsel regler, standarder byggarbetsplats, säkerhetsregler bygg'
      }
    };

    return categories[categorySlug] || {
      title: 'Kategori hittades inte',
      description: 'Den begärda kategorin existerar inte.',
      seoDescription: 'Sidan kunde inte hittas',
      keywords: ''
    };
  };

  const categoryData = getCategoryData(category);

  const pageSEO = {
    title: `${categoryData.title} | StängselPro Kunskapsbank`,
    description: categoryData.seoDescription,
    keywords: categoryData.keywords,
    canonicalUrl: `https://byggstängselpro.se/blogg/kategori/${category}`,
    type: 'website'
  };

  return (
    <SEOProvider pageSEO={pageSEO}>
      <BlogCategory />
    </SEOProvider>
  );
};

export default BlogCategoryPage;