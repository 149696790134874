// src/utils/blog-content.js



const BLOG_POSTS = [
  {
    title: "Den kompletta guiden till byggstängsel: Välj rätt för ditt projekt 2024",
    date: "2024-03-15",
    category: "guider",
    slug: "valja-ratt-byggstangsel",
    featured: true,
    readTime: "8 min",
    excerpt:
      "En omfattande expertguide som hjälper dig välja och implementera rätt byggstängsel för ditt specifika projekt. Baserad på över 20 års erfarenhet i branschen.",
    author: "StängselPro",
    tags: ["byggstängsel", "säkerhet", "byggarbetsplats", "projektplanering"],
    seoDescription:
      "Komplett guide för val av byggstängsel 2024. Expertråd om typer, kostnader, säkerhetskrav och regelverk.",
    content: `
      <article class="prose lg:prose-xl max-w-none">
        <h1 class="text-4xl font-bold mb-6">
          Den kompletta guiden till byggstängsel: Välj rätt för ditt projekt 2024
        </h1>

        <p class="text-lg mb-8">
          Valet av rätt byggstängsel är en kritisk del av varje framgångsrikt byggprojekt.
          Denna omfattande guide hjälper dig navigera genom alla aspekter av byggstängsel,
          från planering och val till implementation och underhåll.
        </p>

        <h2 class="text-2xl font-bold mt-8 mb-4">Varför rätt byggstängsel är avgörande</h2>

        <p class="mb-6">Ett väl valt byggstängsel tjänar flera viktiga syften:</p>

        <h3 class="text-xl font-bold mb-4">Säkerhet och arbetsmiljö</h3>
        <p class="mb-4">
          Ett korrekt installerat byggstängsel skapar en säker arbetsmiljö genom att:
        </p>
        <ul class="list-disc pl-6 mb-6">
          <li class="mb-2">Förhindra obehörigt tillträde till byggarbetsplatsen</li>
          <li class="mb-2">Skydda förbipasserande från byggrisker</li>
          <li class="mb-2">Säkra värdefull utrustning och material</li>
          <li class="mb-2">Uppfylla arbetsmiljölagstiftningens krav</li>
        </ul>

        <h3 class="text-xl font-bold mb-4">Effektiv projekthantering</h3>
        <p class="mb-4">Rätt stängsellösning bidrar till:</p>
        <ul class="list-disc pl-6 mb-6">
          <li class="mb-2">Tydlig avgränsning av arbetsområdet</li>
          <li class="mb-2">Kontrollerade access-punkter för personal och leveranser</li>
          <li class="mb-2">Optimerat arbetsflöde på byggplatsen</li>
          <li class="mb-2">Minskad risk för störningar och förseningar</li>
        </ul>

        <h2 class="text-2xl font-bold mt-8 mb-4">Typer av byggstängsel</h2>

        <h3 class="text-xl font-bold mb-4">Mobilt byggstängsel</h3>
        <p class="mb-4">Det mest flexibla alternativet för moderna byggprojekt.</p>

        <div class="bg-gray-50 p-6 rounded-lg mb-8">
          <h4 class="font-bold mb-3">Fördelar:</h4>
          <ul class="list-disc pl-6 mb-4">
            <li class="mb-2">Snabb leverans</li>
            <li class="mb-2">Enkelt att flytta vid behov</li>
            <li class="mb-2">Kostnadseffektivt för kortare projekt</li>
            <li class="mb-2">Minimalt markarbete krävs</li>
          </ul>

          <h4 class="font-bold mb-3">Idealiskt för:</h4>
          <ul class="list-disc pl-6">
            <li class="mb-2">Kortare byggprojekt (1-6 månader)</li>
            <li class="mb-2">Projekt med föränderliga avgränsningsbehov</li>
            <li class="mb-2">Stadsmiljöer med begränsat utrymme</li>
          </ul>
        </div>

        <h2 class="text-2xl font-bold mt-8 mb-4">Viktiga specifikationer</h2>
        <div class="bg-blue-50 p-6 rounded-lg mb-8">
          <ul class="list-none space-y-2">
            <li>✓ Standardhöjd: 2 meter</li>
            <li>✓ Sektionslängd: 3,5 meter</li>
            <li>✓ Rekommenderad överlappning: 30 cm</li>
            <li>✓ Minimum grindbredd: 4 meter för fordonspassage</li>
          </ul>
        </div>

        <h2 class="text-2xl font-bold mt-8 mb-4">Sammanfattning och rekommendationer</h2>

        <p class="mb-4">
          Ett framgångsrikt byggprojekt kräver noggrann planering av stängsellösningen. Genom att:
        </p>
        <ul class="list-disc pl-6 mb-6">
          <li class="mb-2">Genomföra en grundlig behovsanalys</li>
          <li class="mb-2">Välja rätt typ av stängsel</li>
          <li class="mb-2">Säkerställa korrekt placering</li>
          <li class="mb-2">Implementera regelbunden tillsyn</li>
          <li class="mb-2">Följa gällande säkerhetsföreskrifter</li>
        </ul>

        <p class="mb-8">kan du skapa en säker och effektiv byggarbetsplats.</p>

        <div class="bg-blue-50 p-6 rounded-lg mb-8">
          <h3 class="text-xl font-bold mb-4">Behöver du hjälp med ditt projekt?</h3>
          <p class="mb-4">Vi erbjuder:</p>
          <ul class="list-none space-y-2">
            <li>✓ Kostnadsfri rådgivning</li>
            <li>✓ Snabb offert inom 24h</li>
            <li>✓ Leverans inom 48h</li>
            <li>✓ Flexibla hyresperioder</li>
          </ul>
        </div>

        <div class="flex justify-center gap-4 my-8">
          <a
            href="/#kontakt"
            class="inline-block bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
          >
            Begär offert
          </a>
          <a
            href="/#priser"
            class="inline-block border border-blue-600 text-blue-600 px-6 py-3 rounded-lg hover:bg-blue-50 transition-colors"
          >
            Se priser
          </a>
        </div>
      </article>
    `,
  },
  {
    title: "Säkerhet på byggarbetsplatsen: Nya regler och krav 2024",
    date: "2024-03-10",
    category: "sakerhet",
    slug: "sakerhet-byggarbetsplats-2024",
    featured: true,
    readTime: "6 min",
    excerpt:
      "Uppdaterad guide om säkerhetskrav och regler för byggarbetsplatser under 2024. Få koll på de senaste kraven och hur du säkrar din arbetsplats på bästa sätt.",
    author: "StängselPro",
    tags: ["säkerhet", "byggarbetsplats", "regler", "arbetsmiljö"],
    seoDescription:
      "Komplett guide till säkerhet på byggarbetsplatser 2024. Aktuella regler, krav och praktiska tips för en säker arbetsmiljö.",
    content: `
      <article class="prose lg:prose-xl max-w-none">
        <h1 class="text-4xl font-bold mb-6">
          Säkerhet på byggarbetsplatsen: Nya regler och krav 2024
        </h1>

        <p class="text-lg mb-8">
          En säker byggarbetsplats är avgörande för både personal och förbipasserande.
          I denna guide går vi igenom de senaste säkerhetskraven och hur du implementerar dem
          på ett effektivt sätt.
        </p>

        <h2 class="text-2xl font-bold mt-8 mb-4">Viktigaste nyheterna för 2024</h2>

        <div class="bg-gray-50 p-6 rounded-lg mb-8">
          <ul class="list-disc pl-6">
            <li class="mb-2">Skärpta krav på avgränsning mot allmänheten</li>
            <li class="mb-2">Tydligare regler för skyltning och varningsmarkeringar</li>
            <li class="mb-2">Uppdaterade riktlinjer för tillträdeskontroll</li>
            <li class="mb-2">Nya standarder för byggstängsel och säkerhetsbarriärer</li>
          </ul>
        </div>

        <h2 class="text-2xl font-bold mt-8 mb-4">Grundläggande säkerhetskrav</h2>

        <h3 class="text-xl font-bold mb-4">Avgränsning av arbetsområdet</h3>
        <p class="mb-4">
          En korrekt avgränsning av byggarbetsplatsen är grundläggande för säkerheten:
        </p>
        <ul class="list-disc pl-6 mb-6">
          <li class="mb-2">Tydlig markering av byggarbetsplatsens gränser</li>
          <li class="mb-2">Stabila och säkra byggstängsel</li>
          <li class="mb-2">Kontrollerade in- och utpassager</li>
          <li class="mb-2">Korrekt skyltning och varningar</li>
        </ul>

        <h3 class="text-xl font-bold mb-4">Tillträdeskontroll</h3>
        <p class="mb-4">En effektiv tillträdeskontroll omfattar:</p>
        <ul class="list-disc pl-6 mb-6">
          <li class="mb-2">Registrering av personal på arbetsplatsen</li>
          <li class="mb-2">Säkra grindar och passagesystem</li>
          <li class="mb-2">Besöksrutiner och säkerhetsinstruktioner</li>
          <li class="mb-2">Kontroll av behörigheter och certifikat</li>
        </ul>

        <h2 class="text-2xl font-bold mt-8 mb-4">Praktiska åtgärder</h2>

        <div class="bg-blue-50 p-6 rounded-lg mb-8">
          <h3 class="font-bold mb-3">Checklista för säker byggarbetsplats:</h3>
          <ul class="list-none space-y-2">
            <li>✓ Kompletta byggstängsel runt hela området</li>
            <li>✓ Tydlig skyltning vid alla ingångar</li>
            <li>✓ Säkra och låsbara grindar</li>
            <li>✓ God belysning under dygnets mörka timmar</li>
          </ul>
        </div>

        <h2 class="text-2xl font-bold mt-8 mb-4">Rekommendationer för byggstängsel</h2>

        <div class="bg-gray-50 p-6 rounded-lg mb-8">
          <h4 class="font-bold mb-3">Minimikrav:</h4>
          <ul class="list-disc pl-6">
            <li class="mb-2">Höjd minst 2 meter</li>
            <li class="mb-2">Stabil konstruktion som står emot vind och påfrestningar</li>
            <li class="mb-2">Regelbunden kontroll av stängslens skick</li>
            <li class="mb-2">Säkra kopplingar mellan sektioner</li>
          </ul>
        </div>

        <div class="bg-blue-50 p-6 rounded-lg mb-8">
          <h3 class="text-xl font-bold mb-4">Behöver du säkra din byggarbetsplats?</h3>
          <p class="mb-4">Vi hjälper dig med:</p>
          <ul class="list-none space-y-2">
            <li>✓ Kostnadsfri rådgivning</li>
            <li>✓ Snabb offert inom 24h</li>
            <li>✓ Leverans inom 48h</li>
            <li>✓ Flexibla hyresperioder</li>
          </ul>
        </div>

        <div class="flex justify-center gap-4 my-8">
          <a
            href="/#kontakt"
            class="inline-block bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
          >
            Begär offert
          </a>
          <a
            href="/#priser"
            class="inline-block border border-blue-600 text-blue-600 px-6 py-3 rounded-lg hover:bg-blue-50 transition-colors"
          >
            Se priser
          </a>
        </div>
      </article>
    `,
  },
  {
    title: "Byggstängsel: Allt du behöver veta om regelverk och standarder 2024",
    date: "2024-03-20",
    category: "regelverk",
    slug: "byggstangsel-regelverk-standarder-2024",
    featured: true,
    readTime: "10 min",
    excerpt:
      "En omfattande guide om de senaste regelverken och standarderna för byggstängsel 2024. Lär dig hur du säkerställer att din byggarbetsplats uppfyller alla lagkrav.",
    author: "StängselPro",
    tags: ["byggstängsel", "regelverk", "standarder", "säkerhet", "byggarbetsplats"],
    seoDescription:
      "Uppdaterad guide om byggstängselregelverk och standarder 2024. Säkerställ att din byggarbetsplats uppfyller alla lagkrav och säkerhetsföreskrifter.",
    content: `
      <article class="prose lg:prose-xl max-w-none">
        <h1 class="text-4xl font-bold mb-6">
          Byggstängsel: Allt du behöver veta om regelverk och standarder 2024
        </h1>

        <p class="text-lg mb-8">
          Att följa aktuella regelverk och standarder för byggstängsel är avgörande för säkerheten på din byggarbetsplats. I denna omfattande guide går vi igenom de viktigaste lagarna och riktlinjerna för 2024, så att du kan säkerställa full regelefterlevnad.
        </p>

        <h2 class="text-2xl font-bold mt-8 mb-4">Varför är regelverk och standarder viktiga?</h2>

        <p class="mb-6">
          Regelverk och standarder för byggstängsel finns till för att:
        </p>

        <ul class="list-disc pl-6 mb-6">
          <li class="mb-2">Skydda arbetare och allmänhet från potentiella faror</li>
          <li class="mb-2">Förhindra obehörigt tillträde till byggarbetsplatsen</li>
          <li class="mb-2">Säkerställa en effektiv och säker arbetsmiljö</li>
          <li class="mb-2">Minska risken för rättsliga påföljder vid incidenter</li>
        </ul>

        <h2 class="text-2xl font-bold mt-8 mb-4">Aktuella lagar och föreskrifter för 2024</h2>

        <h3 class="text-xl font-bold mb-4">Arbetsmiljölagen</h3>
        <p class="mb-4">
          Arbetsmiljölagen (AML) ställer krav på att arbetsgivare vidtar alla åtgärder som behövs för att förebygga att arbetstagare utsätts för ohälsa eller olycksfall. Detta inkluderar användning av adekvata byggstängsel.
        </p>

        <h3 class="text-xl font-bold mb-4">Byggnads- och anläggningsarbete (AFS 1999:3)</h3>
        <p class="mb-4">
          Arbetsmiljöverkets föreskrift AFS 1999:3 innehåller specifika krav på säkerhetsåtgärder vid byggnads- och anläggningsarbete, inklusive användning av stängsel för att avgränsa arbetsområdet.
        </p>

        <h3 class="text-xl font-bold mb-4">Plan- och bygglagen</h3>
        <p class="mb-4">
          Plan- och bygglagen reglerar hur byggnadsverk ska utformas och placeras. Detta inkluderar krav på att byggarbetsplatser ska vara säkra och inte utgöra en fara för omgivningen.
        </p>

        <h2 class="text-2xl font-bold mt-8 mb-4">Standarder att känna till</h2>

        <h3 class="text-xl font-bold mb-4">SS-EN 13374:2013+A1:2018</h3>
        <p class="mb-4">
          Denna europeiska standard specificerar krav för temporära skyddsräcken och barriärer, vilket kan tillämpas på byggstängsel för att säkerställa fallskydd och förhindra åtkomst.
        </p>

        <h3 class="text-xl font-bold mb-4">SS-EN 12811-1:2004</h3>
        <p class="mb-4">
          Standarden behandlar krav på ställningar och temporära konstruktioner, inklusive aspekter som kan påverka valet av byggstängsel och dess installation.
        </p>

        <h2 class="text-2xl font-bold mt-8 mb-4">Viktiga krav för byggstängsel</h2>

        <div class="bg-gray-50 p-6 rounded-lg mb-8">
          <h4 class="font-bold mb-3">Allmänna krav:</h4>
          <ul class="list-disc pl-6 mb-4">
            <li class="mb-2">Minsta höjd på 2 meter för att förhindra klättring</li>
            <li class="mb-2">Stabil konstruktion som tål väder och vind</li>
            <li class="mb-2">Inga öppningar större än 100 mm för att förhindra passage</li>
            <li class="mb-2">Tydlig markering och skyltning vid in- och utgångar</li>
          </ul>

          <h4 class="font-bold mb-3">Säkerhetsåtgärder:</h4>
          <ul class="list-disc pl-6">
            <li class="mb-2">Regelbunden inspektion och underhåll</li>
            <li class="mb-2">Användning av låsbara grindar</li>
            <li class="mb-2">Installation av varningsskyltar för potentiella faror</li>
            <li class="mb-2">Belysning vid ingångar och längs stängslet vid dålig sikt</li>
          </ul>
        </div>

        <h2 class="text-2xl font-bold mt-8 mb-4">Konsekvenser av att inte följa regelverket</h2>

        <p class="mb-6">
          Underlåtenhet att följa gällande regelverk kan leda till:
        </p>

        <ul class="list-disc pl-6 mb-6">
          <li class="mb-2">Olyckor och personskador</li>
          <li class="mb-2">Rättsliga påföljder och böter</li>
          <li class="mb-2">Projektförseningar</li>
          <li class="mb-2">Skadat rykte och förlorad förtroende</li>
        </ul>

        <h2 class="text-2xl font-bold mt-8 mb-4">Så säkerställer du efterlevnad</h2>

        <div class="bg-blue-50 p-6 rounded-lg mb-8">
          <h3 class="font-bold mb-3">Steg för att uppfylla kraven:</h3>
          <ul class="list-none space-y-2">
            <li>✓ Genomför en riskbedömning innan projektstart</li>
            <li>✓ Konsultera aktuella lagar och standarder</li>
            <li>✓ Välj byggstängsel som uppfyller alla krav</li>
            <li>✓ Dokumentera alla säkerhetsåtgärder</li>
            <li>✓ Utbilda personalen i säkerhetsrutiner</li>
          </ul>
        </div>

        <h2 class="text-2xl font-bold mt-8 mb-4">Varför hyra byggstängsel från oss?</h2>

        <p class="mb-6">
          Genom att hyra byggstängsel från oss säkerställer du att:
        </p>

        <ul class="list-disc pl-6 mb-6">
          <li class="mb-2">Stängslen uppfyller alla aktuella standarder och regelverk</li>
          <li class="mb-2">Du får expertis och rådgivning vid val av stängsellösning</li>
          <li class="mb-2">Installation och underhåll sker korrekt</li>
          <li class="mb-2">Du kan fokusera på ditt byggprojekt utan oro för säkerheten</li>
        </ul>

        <div class="bg-blue-50 p-6 rounded-lg mb-8">
          <h3 class="text-xl font-bold mb-4">Redo att säkra din byggarbetsplats?</h3>
          <p class="mb-4">Kontakta oss för:</p>
          <ul class="list-none space-y-2">
            <li>✓ Kostnadsfri konsultation</li>
            <li>✓ Snabb leverans av certifierade byggstängsel</li>
            <li>✓ Flexibla hyresavtal anpassade efter ditt projekt</li>
            <li>✓ Professionell installation och support</li>
          </ul>
        </div>

        <div class="flex justify-center gap-4 my-8">
          <a
            href="/#kontakt"
            class="inline-block bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
          >
            Begär offert
          </a>
          <a
            href="/#priser"
            class="inline-block border border-blue-600 text-blue-600 px-6 py-3 rounded-lg hover:bg-blue-50 transition-colors"
          >
            Se priser
          </a>
        </div>
      </article>
    `,
  },
];


class BlogContent {
  constructor() {
    this.postsCache = null;
    this.categoriesCache = null;
  }

  async getAllPosts() {
    if (this.postsCache) {
      return this.postsCache;
    }
    this.postsCache = BLOG_POSTS;
    return this.postsCache;
  }

  async getPost(category, slug) {
    const posts = await this.getAllPosts();
    return posts.find(p => p.category === category && p.slug === slug) || null;
  }

  async getPostsByCategory(category) {
    const posts = await this.getAllPosts();
    return posts.filter(p => p.category === category);
  }

  async getRelatedPosts(currentPost, limit = 2) {
    const posts = await this.getAllPosts();
    return posts
      .filter(post =>
        post.slug !== currentPost.slug &&
        (post.category === currentPost.category ||
         post.tags?.some(tag => currentPost.tags?.includes(tag)))
      )
      .slice(0, limit);
  }

  async getFeaturedPosts(limit = 3) {
    const posts = await this.getAllPosts();
    return posts
      .filter(post => post.featured)
      .slice(0, limit);
  }

  async getCategories() {
    if (this.categoriesCache) return this.categoriesCache;

    const posts = await this.getAllPosts();
    const categories = [
      {
        slug: 'guider',
        title: 'Guide till byggstängsel',
        description: 'Omfattande guider om olika typer av byggstängsel och användningsområden',
        posts: posts.filter(p => p.category === 'guider')
      },
      {
        slug: 'sakerhet',
        title: 'Säkerhet på byggarbetsplatsen',
        description: 'Expertråd och bästa praxis för en säker byggarbetsplats',
        posts: posts.filter(p => p.category === 'sakerhet')
      },
      {
        slug: 'regelverk',
        title: 'Regelverk & Standarder',
        description: 'Aktuell information om regler och standarder inom byggsektorn',
        posts: posts.filter(p => p.category === 'regelverk')
      }
    ];

    this.categoriesCache = categories;
    return categories;
  }

  async searchPosts(query) {
    const posts = await this.getAllPosts();
    const searchTerms = query.toLowerCase().split(' ');

    return posts.filter(post => {
      const searchContent = `
        ${post.title} 
        ${post.excerpt} 
        ${post.tags?.join(' ')} 
        ${post.category}
      `.toLowerCase();

      return searchTerms.every(term => searchContent.includes(term));
    });
  }

  clearCache() {
    this.postsCache = null;
    this.categoriesCache = null;
  }
}

export default new BlogContent();