// src/components/blog/BlogSection.jsx
import React from 'react';
import { Card } from '@/components/ui/card';
import { ArrowRight, Calendar, Clock, ChevronLeft } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import BlogContent from '@/utils/blog-content';

const BlogSection = () => {
  console.log('BlogSection component mounting...');
  const location = useLocation();

  const { data: posts = [], isLoading, error } = useQuery({
    queryKey: ['blogPosts'],
    queryFn: async () => {
      console.log('Fetching blog posts...');
      const allPosts = await BlogContent.getAllPosts();
      console.log('Posts fetched:', allPosts.length);
      return allPosts.sort((a, b) => new Date(b.date) - new Date(a.date));
    },
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  const featuredArticles = posts.filter(post => {
    const isFeatured = post.category === 'guider' || post.featured;
    console.log('Checking article:', {
      title: post.title,
      category: post.category,
      isFeatured
    });
    return isFeatured;
  }).slice(0, 2);

  console.log('Featured articles found:', featuredArticles.length);

  const blogCategories = [
    {
      title: 'Guide till byggstängsel',
      slug: 'guider',
      description: 'Omfattande guider om olika typer av byggstängsel och användningsområden',
      icon: <ArrowRight className="w-6 h-6 text-blue-600" />
    },
    {
      title: 'Säkerhet på byggarbetsplatsen',
      slug: 'sakerhet',
      description: 'Expertråd och bästa praxis för en säker byggarbetsplats',
      icon: <ArrowRight className="w-6 h-6 text-blue-600" />
    },
    {
      title: 'Regelverk & Standarder',
      slug: 'regelverk',
      description: 'Aktuell information om regler och standarder inom byggsektorn',
      icon: <ArrowRight className="w-6 h-6 text-blue-600" />
    }
  ];

  // Loading state
  if (isLoading) {
    console.log('Rendering loading state...');
    return (
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/3 mx-auto mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-2/3 mx-auto mb-12"></div>
            <div className="grid md:grid-cols-2 gap-8">
              {[1, 2].map((i) => (
                <div key={i} className="bg-white rounded-xl p-6">
                  <div className="h-4 bg-gray-200 rounded w-1/4 mb-4"></div>
                  <div className="h-6 bg-gray-200 rounded w-3/4 mb-4"></div>
                  <div className="h-4 bg-gray-200 rounded w-full mb-2"></div>
                  <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }

  // Error state
  if (error) {
    return (
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <div className="bg-red-50 border border-red-200 rounded-lg p-6">
            <h1 className="text-2xl font-bold text-red-600 mb-4">
              Ett fel uppstod vid laddning av artiklar
            </h1>
            <p className="text-gray-600 mb-6">
              Försök att ladda om sidan eller kom tillbaka senare.
            </p>
            <button
              onClick={() => window.location.reload()}
              className="bg-red-600 text-white px-6 py-2 rounded-lg hover:bg-red-700 transition-colors"
            >
              Ladda om sidan
            </button>
          </div>
        </div>
      </section>
    );
  }

  console.log('Rendering main content...');
  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">Kunskapsbank</h1>
          <p className="text-xl text-gray-600">
            Expertkunskap om byggstängsel, säkerhet och byggindustrin
          </p>
        </div>

        {/* Featured Articles */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {featuredArticles.map((article, index) => (
            <Link
              key={index}
              to={`/blogg/${article.category}/${article.slug}`}
              className="block group"
              onClick={() => console.log('Navigating to article:', article.title)}
            >
              <Card className="h-full overflow-hidden hover:shadow-lg transition-shadow">
                <article itemScope itemType="https://schema.org/BlogPosting" className="p-6">
                  <meta itemProp="datePublished" content={article.date} />
                  <meta itemProp="author" content={article.author} />
                  {article.tags?.map(tag => (
                    <meta key={tag} itemProp="keywords" content={tag} />
                  ))}

                  <div className="text-sm text-blue-600 mb-2">
                    {article.category.charAt(0).toUpperCase() + article.category.slice(1)}
                  </div>
                  <h2 className="text-2xl font-semibold mb-3 group-hover:text-blue-600 transition-colors" itemProp="headline">
                    {article.title}
                  </h2>
                  <p className="text-gray-600 mb-4" itemProp="description">
                    {article.excerpt}
                  </p>
                  <div className="flex items-center text-sm text-gray-500 gap-4">
                    <div className="flex items-center">
                      <Calendar className="w-4 h-4 mr-2" />
                      <time dateTime={article.date}>
                        {new Date(article.date).toLocaleDateString('sv-SE')}
                      </time>
                    </div>
                    <div className="flex items-center">
                      <Clock className="w-4 h-4 mr-2" />
                      <span>{article.readTime}</span>
                    </div>
                  </div>
                </article>
              </Card>
            </Link>
          ))}
        </div>

        {/* Categories */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {blogCategories.map((category, index) => (
            <Link
              key={index}
              to={`/blogg/kategori/${category.slug}`}
              className="block group"
              onClick={() => console.log('Navigating to category:', category.title)}
            >
              <Card className="h-full p-6 hover:shadow-lg transition-shadow">
                <div className="flex items-start gap-4">
                  <div className="mt-1">
                    {category.icon}
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-2 group-hover:text-blue-600 transition-colors">
                      {category.title}
                    </h3>
                    <p className="text-gray-600">{category.description}</p>
                  </div>
                </div>
              </Card>
            </Link>
          ))}
        </div>

        {/* Back link */}
        {location.pathname !== '/' && (
          <div className="mt-12">
            <Link
              to="/"
              className="inline-flex items-center text-blue-600 hover:text-blue-800"
            >
              <ChevronLeft className="w-4 h-4 mr-2" />
              Tillbaka till startsidan
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default BlogSection;