import React from 'react';
import { MapPin, Phone, Mail } from 'lucide-react';
import { Card } from '@/components/ui/card';

const LocalLandingPage = ({
  area = "Södermalm",
  description = "Stockholms största stadsdel",
  landmarks = ["Medborgarplatsen", "Slussen"],
  deliveryTime = "24 timmar",
  commonProjects = ["Bostadsbyggen", "Gatuarbeten"]
}) => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold mb-4">
          Byggstängsel {area} - Leverans inom {deliveryTime}
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Professionell uthyrning av byggstängsel i {area}, {description}.
          Snabb leverans och installation i hela området.
        </p>
      </div>

      <div className="grid md:grid-cols-2 gap-8 mb-12">
        <Card className="p-6">
          <h2 className="text-xl font-bold mb-4">Om {area}</h2>
          <p className="text-gray-600 mb-4">
            Vi levererar byggstängsel till hela {area}-området och närliggande stadsdelar.
            Med vår lokala närvaro kan vi garantera snabb service och support.
          </p>
          <ul className="space-y-2">
            <li className="flex items-center gap-2">
              <MapPin className="w-5 h-5 text-blue-600" />
              <span>Täcker hela {area} med omnejd</span>
            </li>
            {landmarks.map((landmark, index) => (
              <li key={index} className="flex items-center gap-2">
                <MapPin className="w-5 h-5 text-blue-600" />
                <span>Leverans nära {landmark}</span>
              </li>
            ))}
          </ul>
        </Card>

        <Card className="p-6">
          <h2 className="text-xl font-bold mb-4">Vanliga projekt i {area}</h2>
          <ul className="space-y-4">
            {commonProjects.map((project, index) => (
              <li key={index} className="flex items-start gap-3">
                <div className="w-6 h-6 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0">
                  <span className="text-blue-600 font-bold">{index + 1}</span>
                </div>
                <span>{project}</span>
              </li>
            ))}
          </ul>
        </Card>
      </div>

      <div className="bg-blue-50 rounded-lg p-8 text-center">
        <h2 className="text-2xl font-bold mb-4">Kontakta oss för {area}</h2>
        <div className="flex flex-col md:flex-row justify-center gap-8 max-w-2xl mx-auto">
          <a href="tel:+46762520046" className="flex items-center gap-2 justify-center">
            <Phone className="w-5 h-5" />
            076-252 00 46
          </a>
          <a href="mailto:info@byggstängselpro.se" className="flex items-center gap-2 justify-center">
            <Mail className="w-5 h-5" />
            info@byggstängselpro.se
          </a>
        </div>
      </div>
    </div>
  );
};

export default LocalLandingPage;