import React from 'react';
import { Card } from '@/components/ui/card';
import { Truck, Calculator, Clock, Phone, BanknoteIcon, CheckCircle } from 'lucide-react';

const Benefits = () => {
  const benefits = [
    {
      icon: Truck,
      title: 'Snabb Leverans',
      description: 'Leverans inom 48 timmar i hela Stockholms län. Vi ser till att du får ditt stängsel när du behöver det.'
    },
    {
      icon: Phone,
      title: 'Snabb Återkoppling',
      description: 'Vi svarar på förfrågningar samma dag och är alltid tillgängliga på telefon för att hjälpa dig.'
    },
    {
      icon: Calculator,
      title: 'Transparent Prissättning',
      description: 'Direktberäkning av pris utan dolda avgifter. Du ser exakt vad du betalar för.'
    },
    {
      icon: Clock,
      title: 'Flexibla Hyresperioder',
      description: 'Anpassa hyrestiden efter ditt projekt, från en månad och uppåt med rabatt vid längre perioder.'
    },
    {
      icon: BanknoteIcon,
      title: 'Konkurrenskraftiga Priser',
      description: 'Tydlig och rättvis prissättning med rabatter vid längre hyresperioder.'
    },
    {
      icon: CheckCircle,
      title: 'Enkel Process',
      description: 'Från beställning till leverans gör vi det enkelt för dig. Vi sköter hela processen.'
    }
  ];

  return (
    <section id="fordelar" className="py-24 bg-secondary-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-secondary-900 mb-4">
            Fördelarna med våra Byggstängsel
          </h2>
          <p className="text-xl text-secondary-600 max-w-3xl mx-auto">
            Vi erbjuder en komplett lösning för byggstängsel med fokus på snabbhet,
            service och enkelhet
          </p>
        </div>

        {/* Bildgalleri */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          <div className="flex flex-col h-full">
            <div className="aspect-[4/3] w-full overflow-hidden rounded-lg shadow-lg">
              <img
                src="/pictures/pic1.jpg"
                alt="Komplett byggstängselsektion med stabil fot"
                className="w-full h-full object-cover"
                loading="lazy"
              />
            </div>
            <p className="mt-2 text-center text-gray-600">
              Komplett byggstängselsektion i varmgalvaniserat stål
            </p>
          </div>
          <div className="flex flex-col h-full">
            <div className="aspect-[4/3] w-full overflow-hidden rounded-lg shadow-lg">
              <img
                src="/pictures/pic2.jpg"
                alt="Detaljvy av byggstängsel med vertikala stag"
                className="w-full h-full object-cover"
                loading="lazy"
              />
            </div>
            <p className="mt-2 text-center text-gray-600">
              Högkvalitativa stängselsektioner med förstärkta vertikala stag
            </p>
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {benefits.map((benefit, index) => (
            <Card
              key={index}
              className="p-6 hover:shadow-lg transition-all duration-300"
            >
              <div className="flex items-center gap-4">
                <benefit.icon className="w-8 h-8 text-primary-600 flex-shrink-0" />
                <h3 className="text-lg font-semibold text-secondary-900">
                  {benefit.title}
                </h3>
              </div>
              <p className="mt-4 text-secondary-600">
                {benefit.description}
              </p>
            </Card>
          ))}
        </div>
      </div>

      {/* Schema Markup för bilderna */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "ImageObject",
          "contentUrl": "/pictures/pic1.jpg",
          "name": "Byggstängsel - Komplett sektion",
          "description": "Komplett byggstängselsektion i varmgalvaniserat stål med stabil fot",
          "creditText": "© Byggstängselpro"
        })}
      </script>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "ImageObject",
          "contentUrl": "/pictures/pic2.jpg",
          "name": "Byggstängsel - Detaljvy",
          "description": "Detaljerad vy av stängselsektion med förstärkta vertikala stag",
          "creditText": "© Byggstängselpro"
        })}
      </script>
    </section>
  );
};

export default Benefits;