import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../ui';
import { Send, Phone, MapPin, Mail } from 'lucide-react';

const CONTACT_PHONE = '076-252 00 46';
const CONTACT_EMAIL = 'info@byggstängselpro.se';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    projectStart: '',
    length: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await fetch('https://formspree.io/f/mjkvayrl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          _replyto: formData.email,
          _subject: `Ny förfrågan från ${formData.name} - ${formData.company}`,
          _cc: CONTACT_EMAIL
        })
      });

      if (!response.ok) {
        throw new Error('Ett fel uppstod när förfrågan skulle skickas.');
      }

      setSubmitted(true);
      setFormData({
        name: '',
        company: '',
        email: '',
        phone: '',
        projectStart: '',
        length: '',
        message: ''
      });
      setTermsAccepted(false);

      alert('Tack för din förfrågan! Vi återkommer inom kort.');

    } catch (error) {
      console.error('Error submitting form:', error);
      setError('Ett fel uppstod när förfrågan skulle skickas. Försök igen eller ring oss direkt.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="kontakt" className="py-24 bg-secondary-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Få en Kostnadsfri Offert
          </h2>
          <p className="text-xl text-gray-600">
            Fyll i formuläret så återkommer vi inom 24 timmar med en detaljerad offert anpassad
            efter dina behov.
          </p>
        </div>

        <div className="grid lg:grid-cols-2 gap-12">
          {/* Vänster sida - Formulär */}
          <Card className="p-8 bg-white shadow-lg">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm text-gray-900 mb-1">Kontaktperson</label>
                  <input
                    type="text"
                    name="name"
                    required
                    className="w-full p-2 border border-gray-300 rounded-lg text-gray-900"
                    value={formData.name}
                    onChange={(e) => setFormData({...formData, name: e.target.value})}
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-900 mb-1">Företag</label>
                  <input
                    type="text"
                    name="company"
                    required
                    className="w-full p-2 border border-gray-300 rounded-lg text-gray-900"
                    value={formData.company}
                    onChange={(e) => setFormData({...formData, company: e.target.value})}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm text-gray-900 mb-1">E-post</label>
                  <input
                    type="email"
                    name="email"
                    required
                    className="w-full p-2 border border-gray-300 rounded-lg text-gray-900"
                    value={formData.email}
                    onChange={(e) => setFormData({...formData, email: e.target.value})}
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-900 mb-1">Telefon</label>
                  <input
                    type="tel"
                    name="phone"
                    required
                    className="w-full p-2 border border-gray-300 rounded-lg text-gray-900"
                    value={formData.phone}
                    onChange={(e) => setFormData({...formData, phone: e.target.value})}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm text-gray-900 mb-1">Önskat startdatum</label>
                  <input
                    type="date"
                    name="projectStart"
                    className="w-full p-2 border border-gray-300 rounded-lg text-gray-900"
                    placeholder="åååå-mm-dd"
                    value={formData.projectStart}
                    onChange={(e) => setFormData({...formData, projectStart: e.target.value})}
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-900 mb-1">
                    Antal meter stängsel (valfritt)
                  </label>
                  <input
                    type="number"
                    name="length"
                    className="w-full p-2 border border-gray-300 rounded-lg text-gray-900"
                    value={formData.length}
                    onChange={(e) => setFormData({...formData, length: e.target.value})}
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm text-gray-900 mb-1">Meddelande (valfritt)</label>
                <textarea
                  name="message"
                  className="w-full p-2 border border-gray-300 rounded-lg text-gray-900 min-h-[120px]"
                  placeholder="Beskriv ditt projekt eller ställ dina frågor här..."
                  value={formData.message}
                  onChange={(e) => setFormData({...formData, message: e.target.value})}
                />
              </div>

              {error && (
                <div className="text-red-500 text-sm">
                  {error}
                </div>
              )}

              {/* Villkorsacceptans */}
              <div className="mt-4">
                <label className="flex items-start gap-3 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                    className="mt-1 w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    required
                  />
                  <span className="text-sm text-gray-600">
                    Jag har läst och accepterar våra{' '}
                    <Link
                      to="/policies"
                      className="text-blue-600 hover:text-blue-800 underline"
                    >
                      villkor och policies
                    </Link>
                    {' '}för fullständig information.
                  </span>
                </label>
              </div>

              <button
                type="submit"
                disabled={isSubmitting || !termsAccepted}
                className={`w-full bg-blue-500 text-white py-3 rounded-lg flex items-center justify-center gap-2 ${
                  !termsAccepted ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'
                }`}
              >
                {isSubmitting ? (
                  <>
                    <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                    Skickar...
                  </>
                ) : (
                  <>
                    <Send className="w-5 h-5" />
                    {termsAccepted ? 'Skicka förfrågan' : 'Godkänn villkor för att fortsätta'}
                  </>
                )}
              </button>
            </form>
          </Card>

          {/* Höger sida - Kontaktinfo och FAQ */}
          <div className="space-y-8">
            {/* Kontaktinformation */}
            <Card className="p-6 bg-white">
              <h3 className="text-lg font-bold text-gray-900 px-2 py-1 bg-blue-500 text-white inline-block rounded mb-4">
                Kontaktinformation
              </h3>
              <div className="space-y-4">
                <div className="flex items-center gap-3">
                  <div className="w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center">
                    <Phone className="w-5 h-5 text-white" />
                  </div>
                  <div>
                    <div className="text-sm text-blue-500">Ring oss</div>
                    <div className="text-gray-900">{CONTACT_PHONE}</div>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <div className="w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center">
                    <Mail className="w-5 h-5 text-white" />
                  </div>
                  <div>
                    <div className="text-sm text-blue-500">E-post</div>
                    <a href={`mailto:${CONTACT_EMAIL}`} className="text-gray-900 hover:text-blue-600">
                      {CONTACT_EMAIL}
                    </a>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <div className="w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center">
                    <MapPin className="w-5 h-5 text-white" />
                  </div>
                  <div>
                    <div className="text-sm text-blue-500">Täckningsområde</div>
                    <div className="text-gray-900">Hela Stockholms län</div>
                  </div>
                </div>
              </div>
            </Card>

            {/* Vanliga frågor */}
            <Card className="p-6 bg-white">
              <h3 className="text-lg font-bold text-gray-900 mb-4">Vanliga frågor</h3>
              <div className="space-y-4">
                <div>
                  <h4 className="font-medium text-gray-900">Hur snabbt kan ni leverera?</h4>
                  <p className="text-gray-600">
                    Vi levererar normalt inom 24-48 timmar i hela Stockholms län.
                  </p>
                </div>
                <div>
                  <h4 className="font-medium text-gray-900">Vad ingår i priset?</h4>
                  <p className="text-gray-600">
                    I grundpriset ingår hyra av stängsel. Transport kan läggas till.
                  </p>
                </div>
                <div>
                  <h4 className="font-medium text-gray-900">Hur lång är minsta hyrestid?</h4>
                  <p className="text-gray-600">
                    Minsta hyrestid är 1 månad, men vi erbjuder rabatt vid längre hyresperioder.
                  </p>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;