// FAQ.jsx
import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Card } from '@/components/ui/card';
const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "Kan ni leverera byggstängsel akut samma dag?",
      answer: "Ja, vi erbjuder expressleverans inom 4 timmar i Stockholms innerstad och närförorter vid akuta behov. Ring oss direkt för tillgänglighet.",
      category: "leverans"
    },
    {
      question: "Hur snabbt levererar ni normalt byggstängsel?",
      answer: "Vi levererar inom 48 timmar till alla adresser i Stockholms län. För de flesta områden kan vi leverera redan nästa arbetsdag.",
      category: "leverans"
    },
    {
      question: "Vad kostar det att hyra byggstängsel?",
      answer: "Grundpriset är från 45 kr per sektion och dag. Vi erbjuder mängdrabatt och upp till 8% rabatt vid längre hyresperioder. Varje sektion är 3,5 meter. Kontakta oss för en skräddarsydd offert.",
      category: "priser"
    },
    {
      question: "Finns det några dolda kostnader?",
      answer: "Nej, vi är transparenta med alla kostnader. Utöver hyran tillkommer endast eventuell försäkring (valfritt) och frakt. Allt specificeras tydligt i offerten.",
      category: "priser"
    },
    {
      question: "Vilka områden i Stockholm täcker ni?",
      answer: "Vi levererar i hela Stockholms län: från Norrtälje i norr till Nynäshamn i söder, och från Södertälje i väst till Vaxholm i öst. Samma villkor och priser gäller i hela länet.",
      category: "täckning"
    },
    {
      question: "Hur fungerar försäkringen?",
      answer: "Vår tillvalsförsäkring täcker eventuella skador på stängslet under hyresperioden. Premien är 5% av hyresbeloppet och ger dig extra trygghet under hela hyrestiden.",
      category: "försäkring"
    },
    {
      question: "Hur lång är minsta hyrestid?",
      answer: "Minsta hyrestid är 1 månad. Vi erbjuder fördelaktiga rabatter: 5% vid 3 månader och 8% vid 6 månader eller längre.",
      category: "hyrestid"
    },
    {
      question: "Hur många meter är en sektion?",
      answer: "En standardsektion är 3,5 meter lång. Detta är den optimala längden för både stabilitet och flexibilitet vid olika typer av projekt.",
      category: "teknisk"
    },
    {
      question: "Vad händer om jag behöver förlänga hyrestiden?",
      answer: "Det går utmärkt att förlänga hyrestiden. Meddela oss bara några dagar innan ursprunglig slutdatum så ordnar vi förlängningen. Samma prisvillkor gäller.",
      category: "hyrestid"
    }
  ];

  return (
    <section className="py-24 bg-white" id="faq">
      <div className="max-w-3xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Vanliga frågor om byggstängsel</h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <Card
              key={index}
              className="overflow-hidden transition-all duration-200"
            >
              <button
                className="w-full p-6 text-left flex justify-between items-center hover:bg-gray-50"
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
                aria-expanded={openIndex === index}
              >
                <span className="font-medium text-lg text-gray-900">{faq.question}</span>
                {openIndex === index ? (
                  <ChevronUp className="w-5 h-5 text-gray-500" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-gray-500" />
                )}
              </button>
              <div
                className={`px-6 transition-all duration-200 ${
                  openIndex === index ? 'pb-6 opacity-100' : 'h-0 opacity-0 overflow-hidden'
                }`}
              >
                <p className="text-gray-600">{faq.answer}</p>
              </div>
            </Card>
          ))}
        </div>
      </div>

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": faqs.map(faq => ({
            "@type": "Question",
            "name": faq.question,
            "acceptedAnswer": {
              "@type": "Answer",
              "text": faq.answer
            }
          }))
        })}
      </script>
    </section>
  );
};

export default FAQSection;