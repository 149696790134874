import React from 'react';
import { Outlet } from 'react-router-dom';
import Navigation from '@/components/common/Navigation';
import Footer from '@/components/common/Footer';
import { SEOProvider, generatePageSEO } from '@/components/seo/SEOProvider';

const MainLayout = () => {
  return (
    <SEOProvider pageSEO={generatePageSEO('/')}>
      <div className="min-h-screen flex flex-col">
        <Navigation />
        <main className="flex-grow">
          <Outlet />
        </main>
        <Footer />
      </div>
    </SEOProvider>
  );
};

export default MainLayout;