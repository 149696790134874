import React from 'react';
import { useParams } from 'react-router-dom';
import { Card } from '@/components/ui/card';
import { SEOProvider, generatePageSEO } from '@/components/seo/SEOProvider';

const BlogCategory = () => {
  const { category } = useParams();

  const categoryInfo = {
    guider: {
      title: 'Guide till byggstängsel',
      description: 'Omfattande guider om olika typer av byggstängsel och användningsområden',
      seoDescription: 'Expertguider om byggstängsel - Allt du behöver veta om olika typer, användningsområden och bästa praxis för byggstängsel.',
      articles: [
        {
          title: 'Komplett guide: Välj rätt byggstängsel för ditt projekt',
          slug: 'valja-ratt-byggstangsel',
          excerpt: 'En omfattande guide som hjälper dig välja rätt typ av byggstängsel för dina specifika projektbehov.',
          publishDate: '2024-03-15',
          readTime: '8 min'
        }
      ]
    },
    sakerhet: {
      title: 'Säkerhet på byggarbetsplatsen',
      description: 'Expertråd och bästa praxis för en säker byggarbetsplats',
      seoDescription: 'Säkerhetsguider för byggarbetsplatser - Expertråd om säkerhetsåtgärder, regelverk och bästa praxis för byggstängsel.',
      articles: [
        {
          title: 'Så säkrar du byggarbetsplatsen enligt nya regler 2024',
          slug: 'sakra-byggarbetsplatsen-2024',
          excerpt: 'Komplett genomgång av säkerhetskrav och rekommendationer för byggarbetsplatser.',
          publishDate: '2024-03-10',
          readTime: '6 min'
        }
      ]
    },
    regelverk: {
      title: 'Regelverk & Standarder',
      description: 'Aktuell information om regler och standarder inom byggsektorn',
      seoDescription: 'Uppdaterad information om regelverk och standarder för byggstängsel och arbetsplatssäkerhet.',
      articles: []
    }
  };

  const currentCategory = categoryInfo[category] || {
    title: 'Kategori hittades inte',
    description: 'Den begärda kategorin existerar inte.',
    seoDescription: 'Sidan kunde inte hittas',
    articles: []
  };

  const pageSEO = {
    title: `${currentCategory.title} | StängselPro Kunskapsbank`,
    description: currentCategory.seoDescription,
    canonicalUrl: `https://byggstängselpro.se/blogg/kategori/${category}`,
    type: 'website'
  };

  return (
    <SEOProvider pageSEO={pageSEO}>
      <div className="max-w-7xl mx-auto px-4 py-12">
        {/* Category Header */}
        <header className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">{currentCategory.title}</h1>
          <p className="text-xl text-gray-600">{currentCategory.description}</p>
        </header>

        {/* Articles Grid */}
        <div className="grid gap-8">
          {currentCategory.articles.length > 0 ? (
            currentCategory.articles.map((article, index) => (
              <Card key={index} className="p-6 hover:shadow-lg transition-shadow">
                <article>
                  <h2 className="text-2xl font-semibold mb-3">
                    <a
                      href={`/blogg/${category}/${article.slug}`}
                      className="hover:text-blue-600 transition-colors"
                    >
                      {article.title}
                    </a>
                  </h2>
                  <p className="text-gray-600 mb-4">{article.excerpt}</p>
                  <div className="flex items-center text-sm text-gray-500">
                    <span>{new Date(article.publishDate).toLocaleDateString('sv-SE')}</span>
                    <span className="mx-2">•</span>
                    <span>{article.readTime} läsning</span>
                  </div>
                </article>
              </Card>
            ))
          ) : (
            <div className="text-center py-12">
              <p className="text-gray-600">
                Inga artiklar finns tillgängliga i denna kategori ännu.
                Kom tillbaka snart för nytt innehåll!
              </p>
            </div>
          )}
        </div>

        {/* Navigation */}
        <nav className="mt-12 text-center">
          <a href="/blogg" className="text-blue-600 hover:text-blue-800">
            ← Tillbaka till alla artiklar
          </a>
        </nav>
      </div>
    </SEOProvider>
  );
};

export default BlogCategory;