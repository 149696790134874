import React, { createContext, useContext, useReducer, useCallback } from 'react';
import ApiService from '@services/api';
import { validateForm } from '@utils/form-validation';
import { calculateTotalPrice } from '@utils/price-calculator';

// Initial state
const initialState = {
  formData: {
    name: '',
    company: '',
    email: '',
    phone: '',
    projectStart: '',
    message: '',
    length: 10,
    sections: 1,
    duration: 7,
    extras: {
      installation: false,
      transport: false,
      insurance: false
    }
  },
  errors: {},
  isSubmitting: false,
  isSubmitted: false,
  priceEstimate: null,
  quote: null,
  draft: null
};

// Action types
const ACTIONS = {
  UPDATE_FIELD: 'UPDATE_FIELD',
  UPDATE_EXTRAS: 'UPDATE_EXTRAS',
  SET_ERRORS: 'SET_ERRORS',
  START_SUBMIT: 'START_SUBMIT',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_ERROR: 'SUBMIT_ERROR',
  UPDATE_PRICE: 'UPDATE_PRICE',
  SET_QUOTE: 'SET_QUOTE',
  SAVE_DRAFT: 'SAVE_DRAFT',
  RESET_FORM: 'RESET_FORM'
};

// Reducer actions
const updateField = (state, action) => ({
  ...state,
  formData: {
    ...state.formData,
    [action.field]: action.value
  },
  errors: {
    ...state.errors,
    [action.field]: undefined
  }
});

const updateExtras = (state, action) => ({
  ...state,
  formData: {
    ...state.formData,
    extras: {
      ...state.formData.extras,
      [action.extra]: action.value
    }
  }
});

const setErrors = (state, action) => ({
  ...state,
  errors: action.errors
});

const startSubmit = (state) => ({
  ...state,
  isSubmitting: true,
  errors: {}
});

const submitSuccess = (state, action) => ({
  ...state,
  isSubmitting: false,
  isSubmitted: true,
  quote: action.quote
});

const submitError = (state, action) => ({
  ...state,
  isSubmitting: false,
  errors: action.errors
});

const updatePrice = (state, action) => ({
  ...state,
  priceEstimate: action.price
});

const setQuote = (state, action) => ({
  ...state,
  quote: action.quote
});

const saveDraft = (state, action) => ({
  ...state,
  draft: action.draft
});

const resetForm = (state) => ({
  ...initialState,
  draft: state.draft
});

// Reducer
const formReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_FIELD:
      return updateField(state, action);
    case ACTIONS.UPDATE_EXTRAS:
      return updateExtras(state, action);
    case ACTIONS.SET_ERRORS:
      return setErrors(state, action);
    case ACTIONS.START_SUBMIT:
      return startSubmit(state);
    case ACTIONS.SUBMIT_SUCCESS:
      return submitSuccess(state, action);
    case ACTIONS.SUBMIT_ERROR:
      return submitError(state, action);
    case ACTIONS.UPDATE_PRICE:
      return updatePrice(state, action);
    case ACTIONS.SET_QUOTE:
      return setQuote(state, action);
    case ACTIONS.SAVE_DRAFT:
      return saveDraft(state, action);
    case ACTIONS.RESET_FORM:
      return resetForm(state);
    default:
      return state;
  }
};

// Create context
const FormContext = createContext(null);

// Context provider
export const FormProvider = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, initialState);

  // Update field
  const updateField = useCallback((field, value) => {
    dispatch({ type: ACTIONS.UPDATE_FIELD, field, value });
  }, []);

  // Update extras
  const updateExtras = useCallback((extra, value) => {
    dispatch({ type: ACTIONS.UPDATE_EXTRAS, extra, value });
  }, []);

  // Validate form
  const validateFormData = useCallback(() => {
    const errors = validateForm(state.formData);
    dispatch({ type: ACTIONS.SET_ERRORS, errors });
    return Object.keys(errors).length === 0;
  }, [state.formData]);

  // Update price estimate
  const updatePriceEstimate = useCallback(() => {
    const { length, sections, duration, extras } = state.formData;
    const price = calculateTotalPrice({ length, sections, duration, extras });
    dispatch({ type: ACTIONS.UPDATE_PRICE, price });
  }, [state.formData]);

  // Save draft
  const saveDraft = useCallback(async () => {
    try {
      const draft = await ApiService.saveDraft(state.formData);
      dispatch({ type: ACTIONS.SAVE_DRAFT, draft });
    } catch (error) {
      console.error('Error saving draft:', error);
    }
  }, [state.formData]);

  // Submit form
  const submitForm = useCallback(async () => {
    if (!validateFormData()) return;

    dispatch({ type: ACTIONS.START_SUBMIT });

    try {
      // Submit form data
      const response = await ApiService.submitContactForm(state.formData);

      // Create quote
      const quote = await ApiService.createQuote(state.formData);

      // Send confirmation email
      await ApiService.sendConfirmationEmail({
        to: state.formData.email,
        quote,
        formData: state.formData
      });

      dispatch({ type: ACTIONS.SUBMIT_SUCCESS, quote });

      // Track conversion if Google Analytics is available
      if (window.gtag) {
        window.gtag('event', 'form_submission', {
          'event_category': 'forms',
          'event_label': 'contact_form',
          'value': state.formData.length
        });
      }

      return quote;
    } catch (error) {
      console.error('Form submission error:', error);
      dispatch({
        type: ACTIONS.SUBMIT_ERROR,
        errors: error.cause || { submit: error.message }
      });
      throw error;
    }
  }, [state.formData, validateFormData]);

  // Reset form
  const resetForm = useCallback(() => {
    dispatch({ type: ACTIONS.RESET_FORM });
  }, []);

  // Context value
  const value = {
    ...state,
    updateField,
    updateExtras,
    validateForm: validateFormData,
    updatePriceEstimate,
    saveDraft,
    submitForm,
    resetForm
  };

  return (
    <FormContext.Provider value={value}>
      {children}
    </FormContext.Provider>
  );
};

// Custom hook for using form context
export const useForm = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useForm must be used within a FormProvider');
  }
  return context;
};

export default FormContext;