// src/components/blog/BlogPost.jsx
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card } from '@/components/ui/card';
import { Calendar, Clock, ChevronLeft, Tag } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import BlogContent from '@/utils/blog-content';

const BlogPost = () => {
  const { category, slug } = useParams();

  const {
    data: article,
    isLoading,
    error
  } = useQuery({
    queryKey: ['blogPost', category, slug],
    queryFn: () => BlogContent.getPost(category, slug),
    staleTime: 1000 * 60 * 5
  });

  const { data: relatedPosts = [] } = useQuery({
    queryKey: ['relatedPosts', category, slug],
    queryFn: () => article ? BlogContent.getRelatedPosts(article, 2) : [],
    enabled: !!article,
    staleTime: 1000 * 60 * 5
  });

  if (isLoading) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-12 animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-3/4 mb-4"/>
        <div className="h-4 bg-gray-200 rounded w-1/4 mb-8"/>
        <div className="space-y-3">
          <div className="h-4 bg-gray-200 rounded w-full"/>
          <div className="h-4 bg-gray-200 rounded w-full"/>
          <div className="h-4 bg-gray-200 rounded w-2/3"/>
        </div>
      </div>
    );
  }

  if (error || !article) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-12">
        <Card className="p-6 text-center">
          <h2 className="text-xl font-semibold text-red-600 mb-2">
            Ett fel uppstod
          </h2>
          <p className="text-gray-600 mb-4">
            Kunde inte ladda artikeln. Försök igen senare.
          </p>
          <Link
            to="/blogg"
            className="text-blue-600 hover:text-blue-800 inline-flex items-center"
          >
            <ChevronLeft className="w-4 h-4 mr-2"/>
            Tillbaka till bloggen
          </Link>
        </Card>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <article className="prose lg:prose-xl max-w-none mb-12">
        {/* Article Header */}
        <header className="mb-8">
          <div className="flex items-center gap-2 text-sm text-blue-600 mb-2">
            <Link
              to={`/blogg/kategori/${article.category}`}
              className="hover:text-blue-800"
            >
              {article.category.charAt(0).toUpperCase() + article.category.slice(1)}
            </Link>
          </div>

          <h1 className="text-4xl font-bold mb-4 text-gray-900">
            {article.title}
          </h1>

          <div className="flex flex-wrap items-center gap-4 text-sm text-gray-600">
            <time className="flex items-center" dateTime={article.date}>
              <Calendar className="w-4 h-4 mr-1"/>
              {new Date(article.date).toLocaleDateString('sv-SE')}
            </time>
            <div className="flex items-center">
              <Clock className="w-4 h-4 mr-1"/>
              {article.readTime}
            </div>
          </div>
        </header>

        {/* Article Content */}
        <div
          dangerouslySetInnerHTML={{ __html: article.content }}
          className="mb-8"
        />

        {/* Tags */}
        {article.tags && (
          <div className="flex flex-wrap gap-2 mb-8">
            {article.tags.map((tag, index) => (
              <Link
                key={index}
                to={`/blogg/tagg/${tag.toLowerCase()}`}
                className="inline-flex items-center px-3 py-1 bg-gray-100 text-sm text-gray-700 rounded-full hover:bg-gray-200"
              >
                <Tag className="w-3 h-3 mr-1"/>
                {tag}
              </Link>
            ))}
          </div>
        )}

        {/* Navigation */}
        <div className="border-t pt-8">
          <Link
            to="/blogg"
            className="inline-flex items-center text-blue-600 hover:text-blue-800"
          >
            <ChevronLeft className="w-4 h-4 mr-2"/>
            Tillbaka till alla artiklar
          </Link>
        </div>
      </article>

      {/* Related Posts */}
      {relatedPosts.length > 0 && (
        <section className="border-t pt-12">
          <h2 className="text-2xl font-bold mb-6">Relaterade artiklar</h2>
          <div className="grid md:grid-cols-2 gap-6">
            {relatedPosts.map((post, index) => (
              <Card key={index} className="p-6 hover:shadow-lg transition-shadow">
                <Link
                  to={`/blogg/${post.category}/${post.slug}`}
                  className="block group"
                >
                  <h3 className="text-xl font-semibold mb-2 group-hover:text-blue-600 transition-colors">
                    {post.title}
                  </h3>
                  <p className="text-gray-600 mb-4">
                    {post.excerpt}
                  </p>
                  <div className="flex items-center text-sm text-gray-500">
                    <Calendar className="w-4 h-4 mr-1"/>
                    <time dateTime={post.date}>
                      {new Date(post.date).toLocaleDateString('sv-SE')}
                    </time>
                  </div>
                </Link>
              </Card>
            ))}
          </div>
        </section>
      )}
    </div>
  );
};

export default BlogPost;