// Konfiguration för priser och rabatter
export const PRICE_CONFIG = {
  basePrice: 45, // Grundpris per sektion per dag
  minLength: 10, // Minimum längd i meter
  maxLength: 1000, // Maximum längd i meter
  minSections: 1, // Minimum antal sektioner
  maxSections: 50, // Maximum antal sektioner
  discounts: {
    duration: {
      180: 0.30, // 30% rabatt för 6 månader eller mer
      90: 0.25,  // 25% rabatt för 3 månader eller mer
      30: 0.20,  // 20% rabatt för 1 månad eller mer
      14: 0.10,  // 10% rabatt för 2 veckor eller mer
    },
    volume: {
      100: 0.15, // 15% rabatt för mer än 100 meter
      50: 0.10,  // 10% rabatt för mer än 50 meter
      25: 0.05,  // 5% rabatt för mer än 25 meter
    }
  },
  extras: {
    installation: {
      pricePerMeter: 50,
      name: 'Installation & montering',
      description: 'Professionell installation av våra experter'
    },
    transport: {
      fixedPrice: 1500,
      name: 'Transport & leverans',
      description: 'Leverans inom 24-48 timmar'
    },
    insurance: {
      percentage: 0.05, // 5% av totalbeloppet
      name: 'Försäkring',
      description: 'Omfattande försäkring under hela hyresperioden'
    }
  }
};

// Beräkna grundpris
export const calculateBasePrice = (length, sections, duration) => {
  const dailyPrice = sections * PRICE_CONFIG.basePrice;
  return dailyPrice * duration;
};

// Beräkna volymrabatt
export const calculateVolumeDiscount = (length, basePrice) => {
  let discountRate = 0;

  if (length >= 100) discountRate = PRICE_CONFIG.discounts.volume[100];
  else if (length >= 50) discountRate = PRICE_CONFIG.discounts.volume[50];
  else if (length >= 25) discountRate = PRICE_CONFIG.discounts.volume[25];

  return basePrice * discountRate;
};

// Beräkna tidsrabatt
export const calculateDurationDiscount = (duration, basePrice) => {
  let discountRate = 0;

  if (duration >= 180) discountRate = PRICE_CONFIG.discounts.duration[180];
  else if (duration >= 90) discountRate = PRICE_CONFIG.discounts.duration[90];
  else if (duration >= 30) discountRate = PRICE_CONFIG.discounts.duration[30];
  else if (duration >= 14) discountRate = PRICE_CONFIG.discounts.duration[14];

  return basePrice * discountRate;
};

// Beräkna extrakostnader
export const calculateExtras = (extras, length, basePrice) => {
  let totalExtras = 0;

  if (extras.installation) {
    totalExtras += length * PRICE_CONFIG.extras.installation.pricePerMeter;
  }

  if (extras.transport) {
    totalExtras += PRICE_CONFIG.extras.transport.fixedPrice;
  }

  if (extras.insurance) {
    totalExtras += basePrice * PRICE_CONFIG.extras.insurance.percentage;
  }

  return totalExtras;
};

// Huvudfunktion för prisberäkning
export const calculateTotalPrice = ({
  length,
  sections,
  duration,
  extras = {}
}) => {
  // Validera indata
  length = Math.min(Math.max(length, PRICE_CONFIG.minLength), PRICE_CONFIG.maxLength);
  sections = Math.min(Math.max(sections, PRICE_CONFIG.minSections), PRICE_CONFIG.maxSections);

  // Beräkna grundpris
  const basePrice = calculateBasePrice(length, sections, duration);

  // Beräkna rabatter
  const volumeDiscount = calculateVolumeDiscount(length, basePrice);
  const durationDiscount = calculateDurationDiscount(duration, basePrice);
  const totalDiscount = volumeDiscount + durationDiscount;

  // Beräkna extrakostnader
  const extrasCost = calculateExtras(extras, length, basePrice - totalDiscount);

  // Beräkna totalpris
  const totalPrice = basePrice - totalDiscount + extrasCost;

  return {
    basePrice: Math.round(basePrice),
    volumeDiscount: Math.round(volumeDiscount),
    durationDiscount: Math.round(durationDiscount),
    totalDiscount: Math.round(totalDiscount),
    extrasCost: Math.round(extrasCost),
    totalPrice: Math.round(totalPrice),
    pricePerDay: Math.round(totalPrice / duration),
    details: {
      length,
      sections,
      duration,
      extras,
      discounts: {
        volume: volumeDiscount > 0,
        duration: durationDiscount > 0,
        volumePercentage: (volumeDiscount / basePrice) * 100,
        durationPercentage: (durationDiscount / basePrice) * 100
      }
    }
  };
};

// Formatera priser för visning
export const formatPrice = (amount, options = {}) => {
  const defaults = {
    style: 'currency',
    currency: 'SEK',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  };

  return new Intl.NumberFormat('sv-SE', { ...defaults, ...options }).format(amount);
};

// Generera prisförslag
export const generateQuote = (calculationResult) => {
  const {
    basePrice,
    totalDiscount,
    extrasCost,
    totalPrice,
    details
  } = calculationResult;

  return {
    quoteId: `Q${Date.now()}`,
    validUntil: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), // Giltigt i 7 dagar
    items: [
      {
        description: `Stängselhyra - ${details.length}m, ${details.sections} sektioner`,
        amount: basePrice,
        unit: 'total'
      },
      ...Object.entries(details.extras)
        .filter(([_, enabled]) => enabled)
        .map(([key]) => ({
          description: PRICE_CONFIG.extras[key].name,
          amount: calculateExtras({ [key]: true }, details.length, basePrice),
          unit: key === 'installation' ? 'per meter' : 'fast pris'
        }))
    ],
    discounts: [
      {
        description: 'Volym- och tidsrabatt',
        amount: -totalDiscount
      }
    ],
    summary: {
      subtotal: basePrice,
      discounts: totalDiscount,
      extras: extrasCost,
      total: totalPrice,
      vat: totalPrice * 0.25, // 25% moms
      totalWithVat: totalPrice * 1.25
    }
  };
};