// SEOProvider.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const defaultSEO = {
  title: 'Byggstängsel Stockholm | Uthyrning & Snabb Leverans 48h | StängselPro',
  description: 'Störst i Stockholm på byggstängsel med expressleverans ✓ ISO-certifierade stängsel ✓ Flexibla hyresperioder ✓ Från 45kr/dag ✓ Hela Stockholms län ✓ Över 1000 nöjda kunder',
  canonicalDomain: 'https://byggstängselpro.se',
  defaultImage: '/images/og-image.jpg',
  company: {
    name: 'StängselPro Stockholm',
    phone: '+46762520046',
    email: 'info@byggstängselpro.se',
    priceRange: '$$',
    foundingDate: '2020',
    openingHours: ['Mo-Fr 07:00-16:00'],
    areaServed: [
      'Stockholm', 'Södertälje', 'Norrtälje', 'Nynäshamn', 'Solna', 'Sundbyberg',
      'Nacka', 'Huddinge', 'Järfälla', 'Täby', 'Danderyd', 'Sollentuna',
      'Upplands Väsby', 'Vallentuna', 'Vaxholm', 'Värmdö', 'Tyresö', 'Haninge',
      'Botkyrka', 'Salem', 'Lidingö', 'Ekerö', 'Österåker', 'Sigtuna'
    ]
  }
};

export const generatePageSEO = (path, pageType = 'website') => {
  const pageSEOConfig = {
    '/': {
      title: 'Byggstängsel Stockholm | Express Leverans 48h | Bästa Priser 2024',
      description: 'Sveriges ledande uthyrning av byggstängsel. ✓ Snabb leverans inom 48h ✓ ISO-certifierad kvalitet ✓ Från 45kr/dag ✓ Hela Stockholm ✓ Alltid fasta priser. Begär offert nu!',
      keywords: 'byggstängsel stockholm, byggstaket uthyrning, byggnadsstaket, temporärt stängsel, mobilt byggstängsel, tillfälligt byggstaket, byggarbetsplats stängsel, hyra byggstängsel stockholm',
      path: '/'
    },
    '/priser': {
      title: 'Priser Byggstängsel Stockholm | Från 45kr/dag | StängselPro',
      description: 'Transparenta priser för byggstängsel uthyrning. ✓ Från 45kr/dag ✓ Mängdrabatter ✓ Flexibla hyresperioder ✓ Snabb leverans i hela Stockholm. Se våra priser!',
      keywords: 'byggstängsel priser, hyra byggstaket kostnad, byggstängsel uthyrning pris, prislist byggstängsel',
      path: '/priser'
    },
    '/omraden': {
      title: 'Byggstängsel Uthyrning | Hela Stockholms Län | StängselPro',
      description: 'Vi levererar byggstängsel i hela Stockholms län. ✓ Samma pris överallt ✓ Snabb leverans ✓ Lokalkännedom. Se leveransområden och priser!',
      keywords: 'byggstängsel stockholm län, stängseluthyrning stockholm, byggstaket leverans',
      path: '/omraden'
    },
    'area': (area) => ({
      title: `Byggstängsel ${area} | Expressleverans 48h | Bästa Priser 2024`,
      description: `Hyr byggstängsel i ${area}. ✓ Leverans inom 48h ✓ Från 45kr/dag ✓ ISO-certifierad kvalitet ✓ Lokalkännedom. Få offert direkt!`,
      keywords: `byggstängsel ${area.toLowerCase()}, stängseluthyrning ${area.toLowerCase()}, byggstaket ${area.toLowerCase()}, hyra byggstängsel ${area.toLowerCase()}`,
      path: `/stockholm/${area.toLowerCase()}`
    })
  };

  return typeof pageSEOConfig[path] === 'function'
    ? pageSEOConfig[path](pageType)
    : pageSEOConfig[path] || pageSEOConfig[pageType];
};

export const SEOProvider = ({ children, pageSEO = {} }) => {
  const location = useLocation();
  const seo = { ...defaultSEO, ...pageSEO };
  const fullTitle = seo.title || defaultSEO.title;
  const fullUrl = `${seo.canonicalDomain}${location.pathname}`;

  // Enhanced structured data with more specific product offerings
  const structuredData = {
    "@context": "https://schema.org",
    "@graph": [
      // Organization
      {
        "@type": "Organization",
        "@id": `${seo.canonicalDomain}/#organization`,
        "name": seo.company.name,
        "url": seo.canonicalDomain,
        "logo": {
          "@type": "ImageObject",
          "url": `${seo.canonicalDomain}/logo.png`,
          "width": "180",
          "height": "60"
        },
        "foundingDate": seo.company.foundingDate,
        "email": seo.company.email,
        "telephone": seo.company.phone,
        "areaServed": {
          "@type": "State",
          "name": "Stockholm County",
          "containsPlace": seo.company.areaServed.map(area => ({
            "@type": "City",
            "name": area
          }))
        },
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": seo.company.phone,
          "contactType": "customer service",
          "areaServed": "SE",
          "availableLanguage": ["Swedish", "English"],
          "hoursAvailable": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
            "opens": "07:00",
            "closes": "16:00"
          }
        }
      },
      // Local Business
      {
        "@type": "LocalBusiness",
        "@id": `${seo.canonicalDomain}/#localbusiness`,
        "name": seo.company.name,
        "image": `${seo.canonicalDomain}${seo.defaultImage}`,
        "telephone": seo.company.phone,
        "email": seo.company.email,
        "priceRange": seo.company.priceRange,
        "address": {
          "@type": "PostalAddress",
          "addressRegion": "Stockholm",
          "addressCountry": "SE"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "59.3293",
          "longitude": "18.0686"
        },
        "openingHoursSpecification": {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
          "opens": "07:00",
          "closes": "16:00"
        }
      },
      // Product
      {
        "@type": "Product",
        "name": "Byggstängsel Uthyrning",
        "description": "Professionella byggstängsel för uthyrning med snabb leverans i Stockholm",
        "brand": {
          "@type": "Brand",
          "name": "StängselPro"
        },
        "offers": {
          "@type": "AggregateOffer",
          "priceCurrency": "SEK",
          "lowPrice": "45",
          "highPrice": "95",
          "priceValidUntil": "2024-12-31",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock",
          "seller": {
            "@type": "Organization",
            "name": seo.company.name
          }
        }
      }
    ]
  };

  // Add FAQ schema if on homepage
  if (location.pathname === '/') {
    structuredData["@graph"].push({
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "Hur snabbt kan ni leverera byggstängsel?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Vi levererar inom 48 timmar i hela Stockholms län. Vid akuta behov erbjuder vi expressleverans inom 4 timmar i utvalda områden."
          }
        },
        {
          "@type": "Question",
          "name": "Vad kostar det att hyra byggstängsel?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Priserna börjar från 45 kr per sektion och dag. Vi erbjuder mängdrabatter och upp till 8% rabatt vid längre hyresperioder."
          }
        },
        {
          "@type": "Question",
          "name": "Vilka områden levererar ni till?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Vi täcker hela Stockholms län, från Norrtälje till Nynäshamn och från Södertälje till Vaxholm."
          }
        }
      ]
    });
  }

  return (
    <>
      <Helmet>
        <html lang="sv-SE" />
        <title>{fullTitle}</title>
        <meta name="description" content={seo.description} />
        {seo.keywords && <meta name="keywords" content={seo.keywords} />}
        <link rel="canonical" href={fullUrl} />

        {/* Performance optimizations */}
        <link rel="preload" as="image" href="/logo.png" />
        <link rel="preload" as="image" href={seo.defaultImage} />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* Mobile optimization */}
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
        <meta name="theme-color" content="#0ea5e9" />

        {/* Open Graph */}
        <meta property="og:site_name" content={seo.company.name} />
        <meta property="og:title" content={fullTitle} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={fullUrl} />
        <meta property="og:image" content={`${seo.canonicalDomain}${seo.defaultImage}`} />
        <meta property="og:locale" content="sv_SE" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={fullTitle} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={`${seo.canonicalDomain}${seo.defaultImage}`} />

        {/* Local Business */}
        <meta name="geo.region" content="SE-AB" />
        <meta name="geo.placename" content="Stockholm" />
        <meta name="geo.position" content="59.3293;18.0686" />
        <meta name="ICBM" content="59.3293, 18.0686" />

        {/* Additional meta tags */}
        <meta name="format-detection" content="telephone=no" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      {children}
    </>
  );
};