// Regex-mönster för validering
const patterns = {
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  phone: /^(\+?46|0)[\s\-]?7[\d\s\-]{8,}$/, // Svenskt mobilnummer
  organizationNumber: /^(\d{6}|\d{8})-?(\d{4})$/, // Svenskt organisationsnummer
  postalCode: /^\d{3}\s?\d{2}$/, // Svenskt postnummer
};

// Valideringsregler för kontaktformuläret
const contactFormRules = {
  name: {
    required: true,
    label: 'Namn',
    minLength: 2,
    message: 'Vänligen ange ditt namn'
  },
  company: {
    required: true,
    label: 'Företag',
    minLength: 2,
    message: 'Vänligen ange företagsnamn'
  },
  email: {
    required: true,
    label: 'E-post',
    pattern: patterns.email,
    message: 'Vänligen ange en giltig e-postadress'
  },
  phone: {
    required: true,
    label: 'Telefon',
    pattern: patterns.phone,
    message: 'Vänligen ange ett giltigt telefonnummer'
  },
  projectStart: {
    required: true,
    label: 'Startdatum',
    validate: (value) => {
      const date = new Date(value);
      const today = new Date();
      if (date < today) {
        return 'Startdatum kan inte vara i det förflutna';
      }
      return '';
    }
  },
  message: {
    required: false,
    label: 'Meddelande',
    maxLength: 1000,
    validate: (value) => {
      if (value && value.length > 1000) {
        return 'Meddelandet får inte vara längre än 1000 tecken';
      }
      return '';
    }
  }
};

// Hjälpfunktioner
const validateOrgNumber = (number) => {
  const cleanNumber = number.replace(/\D/g, '');

  if (cleanNumber.length !== 10 && cleanNumber.length !== 12) {
    return false;
  }

  const digits = cleanNumber.split('').map(Number);
  let sum = 0;
  let isEven = true;

  for (let i = digits.length - 2; i >= 0; i--) {
    let value = digits[i];

    if (isEven) {
      value *= 2;
      if (value > 9) {
        value -= 9;
      }
    }

    sum += value;
    isEven = !isEven;
  }

  const checksum = (10 - (sum % 10)) % 10;
  return checksum === digits[digits.length - 1];
};

const validatePostalCode = (code) => {
  const cleanCode = code.replace(/\s/g, '');
  return patterns.postalCode.test(cleanCode);
};

const validateEmail = (email) => {
  return patterns.email.test(email);
};

const validatePhone = (phone) => {
  return patterns.phone.test(phone);
};

const formatPhone = (phone) => {
  const numbers = phone.replace(/\D/g, '');

  if (numbers.startsWith('0')) {
    return numbers.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1-$2 $3 $4');
  } else if (numbers.startsWith('46')) {
    return '+' + numbers.replace(/(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2-$3 $4 $5');
  }

  return phone;
};

const formatOrgNumber = (number) => {
  const cleaned = number.replace(/\D/g, '');
  if (cleaned.length === 10) {
    return cleaned.replace(/(\d{6})(\d{4})/, '$1-$2');
  }
  return number;
};

const formatPostalCode = (code) => {
  const cleaned = code.replace(/\D/g, '');
  return cleaned.replace(/(\d{3})(\d{2})/, '$1 $2');
};

const validateDate = (date) => {
  const selectedDate = new Date(date);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (selectedDate < today) {
    return 'Datumet kan inte vara i det förflutna';
  }

  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  if (selectedDate > oneYearFromNow) {
    return 'Datumet kan inte vara mer än 1 år framåt i tiden';
  }

  return '';
};

const validateMessage = (message, maxLength = 1000) => {
  if (message && message.length > maxLength) {
    return `Meddelandet får inte vara längre än ${maxLength} tecken`;
  }

  const scriptTags = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
  if (scriptTags.test(message)) {
    return 'Meddelandet innehåller otillåtet innehåll';
  }

  return '';
};

const validateDimensions = (length, sections) => {
  const errors = {};

  if (length < 10 || length > 1000) {
    errors.length = 'Längden måste vara mellan 10 och 1000 meter';
  }

  if (sections < 1 || sections > 50) {
    errors.sections = 'Antal sektioner måste vara mellan 1 och 50';
  }

  return errors;
};

const validateForm = (formData, rules = contactFormRules) => {
  const errors = {};

  Object.keys(rules).forEach(fieldName => {
    const value = formData[fieldName];
    const rule = rules[fieldName];

    if (rule.required && !value) {
      errors[fieldName] = `${rule.label} krävs`;
      return;
    }

    if (!value && !rule.required) {
      return;
    }

    if (rule.pattern && !rule.pattern.test(value)) {
      errors[fieldName] = rule.message || `Ogiltigt ${rule.label}`;
      return;
    }

    if (rule.minLength && value.length < rule.minLength) {
      errors[fieldName] = `${rule.label} måste vara minst ${rule.minLength} tecken`;
      return;
    }

    if (rule.maxLength && value.length > rule.maxLength) {
      errors[fieldName] = `${rule.label} får inte vara längre än ${rule.maxLength} tecken`;
      return;
    }

    if (rule.validate) {
      const customError = rule.validate(value);
      if (customError) {
        errors[fieldName] = customError;
      }
    }
  });

  return errors;
};

// Export alla funktioner och konstanter
export {
  patterns,
  contactFormRules,
  validateOrgNumber,
  validatePostalCode,
  validateEmail,
  validatePhone,
  formatPhone,
  formatOrgNumber,
  formatPostalCode,
  validateDate,
  validateMessage,
  validateDimensions,
  validateForm
};