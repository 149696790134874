// src/pages/locations/AreaPage.jsx
import React from 'react';
import { useParams } from 'react-router-dom';
import LocalLandingPage from '@/components/locations/LocalLandingPage';
import { SEOProvider, generatePageSEO } from '@/components/seo/SEOProvider';

const AreaPage = () => {
  const { area } = useParams();

  return (
    <SEOProvider pageSEO={generatePageSEO('area', area)}>
      <LocalLandingPage area={area} />
    </SEOProvider>
  );
};

export default AreaPage;